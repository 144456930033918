
import { defineComponent } from 'vue';
import { useVModel } from 'vue-composable';
import { BanOutline as WarningIcon } from '@vicons/ionicons5';

export default defineComponent({
  name: 'CannotDeleteSurveyModal',
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  components: { WarningIcon },
  setup(props) {
    const showModalRef = useVModel(props, 'showModal');
    const closeModal = () => {
      showModalRef.value = false;
    };
    return {
      showModalRef,
      closeModal
    };
  }
});
