interface Formatters {
  formatDate: (dateString: string) => string;
}
export function useFormatters(): Formatters {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    // get month with padded zero
    const month = `0${date.getMonth() + 1}`.slice(-2);
    // get day with padded zero
    const day = `0${date.getDate()}`.slice(-2);
    // get year
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };
  return { formatDate };
}
