import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    title: "Confirm Reset",
    show: _ctx.showModalRef,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModalRef) = $event)),
    style: {"width":"35%"},
    preset: "card"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_n_button, {
        type: "tertiary",
        onClick: _cache[0] || (_cache[0] = () => (_ctx.showModalRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Cancel")
        ]),
        _: 1
      }),
      _createVNode(_component_n_button, {
        type: "primary",
        onClick: _ctx.confirmReset
      }, {
        default: _withCtx(() => [
          _createTextVNode("Confirm")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, "Are you sure you want to reset the value on " + _toDisplayString(_ctx.field) + "?", 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.valueRef) + " will be reverted to " + _toDisplayString(_ctx.reset) + ".", 1)
    ]),
    _: 1
  }, 8, ["show"]))
}