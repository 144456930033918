import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0575bd97"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "survey-your-network" }
const _hoisted_2 = { class: "survey-your-network__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h1 = _resolveComponent("n-h1")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_remove_icon = _resolveComponent("remove-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_form = _resolveComponent("n-form")!
  const _component_add_icon = _resolveComponent("add-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_n_h1, null, {
        default: _withCtx(() => [
          _createTextVNode("Your Network")
        ]),
        _: 1
      }),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_n_form, {
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_space, {
            vertical: "",
            align: "center"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputValue, (item, index) => {
                return (_openBlock(), _createBlock(_component_n_space, {
                  key: item,
                  style: {"width":"100%","display":"flex"}
                }, {
                  default: _withCtx(() => [
                    (_ctx.networkType !== 1)
                      ? (_openBlock(), _createBlock(_component_n_form_item, {
                          key: 0,
                          path: `nameRule-${index}`,
                          label: index === 0 ? 'Person:' : '',
                          class: "survey-your-network__person",
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_n_input, {
                              ref_for: true,
                              ref: "nameRef",
                              value: item.name,
                              "onUpdate:value": ($event: any) => ((item.name) = $event),
                              type: "text",
                              class: "survey-your-network__answers",
                              placeholder: `Person #${index + 1}`,
                              maxlength: "1000",
                              style: {"margin-top":"0.5rem"}
                            }, null, 8, ["value", "onUpdate:value", "placeholder"])
                          ]),
                          _: 2
                        }, 1032, ["path", "label"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_n_form_item, {
                      path: `organizationRule-${index}`,
                      label: index === 0 ? 'Organization:' : '',
                      class: "survey-your-network__organization",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_n_input, {
                          ref_for: true,
                          ref: "organizationRef",
                          value: item.organization,
                          "onUpdate:value": ($event: any) => ((item.organization) = $event),
                          type: "text",
                          class: "survey-your-network__answers",
                          placeholder: "Organization",
                          maxlength: "1000",
                          style: {"margin-top":"0.5rem"}
                        }, null, 8, ["value", "onUpdate:value"])
                      ]),
                      _: 2
                    }, 1032, ["path", "label"]),
                    _createVNode(_component_n_form_item, {
                      path: `organizationTypeRule-${index}`,
                      label: index === 0 ? 'Organization Type:' : '',
                      class: "survey-your-network__organization"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_component_n_select, {
                          key: index,
                          ref_for: true,
                          ref: "organizationTypeRef",
                          placeholder: "Organization Type",
                          value: item.organizationType,
                          "onUpdate:value": ($event: any) => ((item.organizationType) = $event),
                          options: _ctx.organizationTypeListRef,
                          class: "survey-your-network__select"
                        }, null, 8, ["value", "onUpdate:value", "options"]))
                      ]),
                      _: 2
                    }, 1032, ["path", "label"]),
                    _createVNode(_component_n_space, {
                      vertical: "",
                      style: {"margin-top":"1.5rem"}
                    }, {
                      default: _withCtx(() => [
                        (index !== 0 || _ctx.inputValue.length > 1)
                          ? (_openBlock(), _createBlock(_component_n_button, {
                              key: 0,
                              class: "survey-your-network__remove",
                              onClick: ($event: any) => (_ctx.onRemove(index)),
                              text: ""
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_n_icon, {
                                  size: "20",
                                  style: {"margin-top":"1.8rem"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_remove_icon)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rules"]),
      _createVNode(_component_n_space, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_n_button, {
            type: "primary",
            class: "add-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addInput())),
            disabled: _ctx.disableButton
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_n_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_add_icon, { class: "add-button-icon" })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Add Entry ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ])
  ]))
}