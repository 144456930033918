import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-222e5023"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "summary-section-wrapper" }
const _hoisted_2 = { class: "summary-section" }
const _hoisted_3 = { class: "summary-section-item" }
const _hoisted_4 = { class: "summary-section-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h1 = _resolveComponent("n-h1")!
  const _component_DataSummary = _resolveComponent("DataSummary")!
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_tabs = _resolveComponent("n-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_h1, null, {
      default: _withCtx(() => [
        _createTextVNode("Summary")
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_n_tabs, { type: "line" }, {
        default: _withCtx(() => [
          _createVNode(_component_n_tab_pane, { name: "Organization" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_DataSummary, {
                  data: _ctx.organizationAggregatedData,
                  "onUpdate:data": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.organizationAggregatedData) = $event)),
                  summaryType: "organization",
                  organizationTypeList: _ctx.organizationTypeListRef
                }, null, 8, ["data", "organizationTypeList"])
              ])
            ]),
            _: 1
          }),
          (_ctx.showPersonSummaryRef)
            ? (_openBlock(), _createBlock(_component_n_tab_pane, {
                key: 0,
                name: "Person"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.showPersonSummaryRef)
                      ? (_openBlock(), _createBlock(_component_DataSummary, {
                          key: 0,
                          data: _ctx.personAggregatedData,
                          "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.personAggregatedData) = $event)),
                          summaryType: "person",
                          organizationTypeList: _ctx.organizationTypeListRef
                        }, null, 8, ["data", "organizationTypeList"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}