
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { AddSharp as CreateIcon, PencilOutline as EditIcon, MailOutline as SendIcon, DownloadOutline as ExportIcon, TrashBinOutline as DeleteIcon } from '@vicons/ionicons5';
import { useRouter } from 'vue-router';
import { useMessage } from 'naive-ui';
import { DateTime } from 'luxon';
import { Parser } from 'json2csv';
import { PageState, Survey, Response, RegionDTO } from '@/types';
import { useFormatters } from '@/composables/useFormatters';
import LoadingBar from '@/objects/loading-bar';
import surveyService from '@/services/survey-service';
import responseService from '@/services/response-service';
import SendModal from '@/components/admin/SendModal.vue';
import ConfirmSurveyDeleteModal from '../survey/ConfirmSurveyDeleteModal.vue';
import CannotDeleteSurveyModal from '../survey/CannotDeleteSurveyModal.vue';
import { useAuth0 } from '../../auth/index';

export default defineComponent({
  name: 'SurveyAdminList',
  components: { SendModal, CreateIcon, EditIcon, SendIcon, ExportIcon, DeleteIcon, ConfirmSurveyDeleteModal, CannotDeleteSurveyModal },
  setup() {
    const { formatDate } = useFormatters();
    const state = reactive<
      PageState<Survey[]> & {
        showSendModal: boolean;
        surveyId: string;
        surveyName: string;
        selectedSurvey: Survey | null;
        showConfirmDeleteModal: boolean;
        showCannotDeleteModal: boolean;
      }
    >({
      loaded: false,
      data: [] as Survey[],
      showSendModal: false,
      surveyId: '',
      surveyName: '',
      selectedSurvey: null,
      showConfirmDeleteModal: false,
      showCannotDeleteModal: false
    });
    const { isAdmin } = useAuth0().state;

    const message = useMessage();
    const loadingBar = new LoadingBar();
    const surveyRegions = ref(new Set<RegionDTO>());
    const responseState = reactive<PageState<Response[]>>({
      loaded: false,
      data: [] as Response[]
    });

    const router = useRouter();
    loadingBar.start();

    onMounted(async () => {
      loadingBar.start();
      try {
        const list = await surveyService.getList();
        state.data = list;
        state.loaded = true;

        const uniqueRegionIds = list.reduce((acc, survey) => {
          if (!survey.region) return acc;
          if (survey.region.id && !acc.has(survey.region.id)) {
            acc.add(survey.region.id);
          }
          return acc;
        }, new Set<string>());
        // map to region names
        surveyRegions.value = new Set<RegionDTO>(
          Array.from(uniqueRegionIds).map((id) => {
            const survey = list.find((s) => s.region?.id === id);
            return survey?.region as RegionDTO;
          })
        );
        loadingBar.finish();
      } catch (error) {
        loadingBar.error();
        message.error('Failed to load surveys or user data');
      }
    });

    const loadResponse = (data: Response[]) => {
      responseState.data = data;
      responseState.loaded = true;
      loadingBar.finish();
    };
    const removeSurveyFromList = (id: string) => {
      state.data = state.data.filter((survey) => survey.id !== id);
    };

    const deleteSurvey = async (id: string) => {
      state.selectedSurvey = state.data.find((survey) => survey.id === id) ?? null;
      if (!state.selectedSurvey) {
        message.error('Failed to find survey');
        return;
      }

      if ((state.selectedSurvey?.completedCount ?? 0) > 0) {
        state.showCannotDeleteModal = true;
        return;
      }
      state.showConfirmDeleteModal = true;
    };

    const downloadCSVData = async (id: string, surveyName: string) => {
      await responseService
        .getList(id)
        .then((response) => {
          if (response) {
            loadResponse(response);
          }
        })
        .catch(() => {
          loadingBar.error();
          message.error(`Failed to download responses for ${surveyName}`);
        });
      const completedResponses = responseState.data.filter((r) => r.completedOn !== null);
      const currentSurvey = state.data.find((survey) => survey.id === id);

      const typeTextReducer = (arr: { key: number; label: string }[] | undefined): { [key: number]: string } | undefined =>
        arr?.reduce(
          (accumulator, orgType) => ({
            ...accumulator,
            [orgType.key]: orgType.label
          }),
          {}
        );

      const orgTypeText = typeTextReducer(currentSurvey?.organizationTypeList);
      const networkTypeText = typeTextReducer(currentSurvey?.participationNetworkTypeList);
      const networkInteractionsTypeText = typeTextReducer(currentSurvey?.questions.interactions.options);

      interface RelationshipKeys {
        [key: `relationshipKey${number}`]: number;
      }

      const networkRelationshipsKeys: RelationshipKeys = {
        relationshipKey1: 0,
        relationshipKey2: 0,
        relationshipKey3: 0,
        relationshipKey4: 0,
        relationshipKey5: 0,
        relationshipKey6: 0,
        relationshipKey7: 0,
        relationshipKey8: 0,
        relationshipKey9: 0,
        relationshipKey10: 0
      };

      const flattenedResponses = completedResponses.flatMap((response) => {
        const yourProfile = response;
        const yourProfileInfo = response.info;

        return yourProfile.network.yourNetwork.map((person) => ({
          name: yourProfileInfo.name,
          email: yourProfileInfo.email,
          role: yourProfileInfo.role,
          organization: yourProfileInfo.organization,
          organizationType: orgTypeText?.[yourProfileInfo.organizationType as number],
          zipCode: yourProfileInfo.zipCode,
          participationInNetworkType: networkTypeText?.[yourProfileInfo.participationInNetworkType as number],
          networkContactName: person.name,
          networkContactOrganization: person.organization,
          networkInteractions: networkInteractionsTypeText?.[person.interactionKey],
          ...networkRelationshipsKeys,
          ...person.relationshipKeys.reduce(
            (accumulator, key) => ({
              ...accumulator,
              [`relationshipKey${key}`]: 1
            }),
            {}
          )
        }));
      });

      const fields = [
        {
          label: 'Name',
          value: 'name'
        },
        {
          label: 'Email',
          value: 'email'
        },
        {
          label: 'Role',
          value: 'role'
        },
        {
          label: 'Organization',
          value: 'organization'
        },
        {
          label: 'Organization Type',
          value: 'organizationType'
        },
        {
          label: 'Zip Code',
          value: 'zipCode'
        },
        {
          label: 'Participation in Network Type',
          value: 'participationInNetworkType'
        },
        {
          label: 'My Network Contact',
          value: 'networkContactName'
        },
        {
          label: 'My Network Contact Organization',
          value: 'networkContactOrganization'
        },
        ...(currentSurvey
          ? currentSurvey.questions.relationships.options.map((option, index) => ({
              label: option.label,
              value: `relationshipKey${index + 1}`
            }))
          : []),
        {
          label: 'My Network Interactions',
          value: 'networkInteractions'
        }
      ];

      const json2csvParser = new Parser({ fields });
      const csv = json2csvParser.parse(flattenedResponses);

      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = `${surveyName}-${DateTime.now().toISODate()}.csv`;
      anchor.click();
    };

    return {
      ...toRefs(state),
      createSurvey: (): void => {
        router.push(`/admin/survey/new`);
      },
      viewResults: (id: string): void => {
        router.push(`/admin/survey/${id}/results`);
      },
      editSurvey: (id: string): void => {
        router.push(`/admin/survey/${id}`);
      },
      sendSurvey: (id: string, surveyName: string): void => {
        loadingBar.finish();
        state.surveyId = id;
        state.surveyName = surveyName;
        state.showSendModal = true;
      },
      exportSurvey: (id: string, surveyName: string): void => {
        downloadCSVData(id, surveyName);
      },
      formatDate,
      surveyRegions,
      isAdmin,
      deleteSurvey,
      removeSurveyFromList
    };
  }
});
