import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row align-items-center profile-header" }
const _hoisted_3 = { class: "col-md-2 mb-3" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-md text-center text-md-left" }
const _hoisted_6 = { class: "lead text-muted" }
const _hoisted_7 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.user?.picture,
          alt: "User's profile picture",
          class: "rounded-circle img-fluid profile-picture"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.user?.name), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.user?.email), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(JSON.stringify(_ctx.user, null, 2)), 1)
  ]))
}