
import { defineComponent, PropType, ref, watch } from 'vue';
import { useVModel } from 'vue-composable';
import { SelectOption, FormInst, FormRules, FormValidationError } from 'naive-ui';

export default defineComponent({
  name: 'SurveyYourInfo',
  props: {
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    organization: {
      type: String,
      default: ''
    },
    organizationType: {
      type: Number as PropType<number | null>,
      default: null
    },
    zipCode: {
      type: String,
      default: ''
    },
    participationInNetwork: {
      type: Number as PropType<number | null>,
      default: null
    },
    organizationTypeList: {
      type: Object as PropType<Array<SelectOption>>
    },
    participationInNetworkList: {
      type: Object as PropType<Array<SelectOption>>
    },
    participationInNetworkQuestion: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const nameRef = useVModel(props, `name`);
    const emailRef = useVModel(props, `email`);
    const roleRef = useVModel(props, `role`);
    const organizationRef = useVModel(props, `organization`);
    const zipCodeRef = useVModel(props, `zipCode`);
    const participationInNetworkRef = useVModel(props, `participationInNetwork`);
    const organizationType = useVModel(props, `organizationType`);
    const organizationTypeValue = ref(organizationType.value);
    const participationInNetworkValue = ref(participationInNetworkRef.value);
    const participationInNetworkQuestionRef = useVModel(props, `participationInNetworkQuestion`);

    watch(organizationTypeValue, (newVal) => {
      organizationType.value = newVal;
    });

    watch(participationInNetworkValue, (newVal) => {
      participationInNetworkRef.value = newVal;
    });

    const formRef = ref<FormInst | null>(null);

    const rules: FormRules = {
      nameRule: [
        {
          required: true,
          validator() {
            if (!nameRef.value) {
              return new Error('Please enter your name.');
            }
            return true;
          },
          trigger: 'change'
        }
      ],
      emailRule: [
        {
          required: true,
          validator() {
            if (!emailRef.value) {
              return new Error('Please enter your email');
            }
            if (!/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(emailRef.value)) {
              return new Error('Please enter a valid email.');
            }
            return true;
          },
          trigger: 'change'
        }
      ],
      roleRule: [
        {
          required: true,
          validator() {
            if (!roleRef.value) {
              return new Error('Please enter your role.');
            }
            return true;
          },
          trigger: 'change'
        }
      ],
      organizationRule: [
        {
          required: true,
          validator() {
            if (!organizationRef.value) {
              return new Error('Please enter your organization.');
            }
            return true;
          },
          trigger: 'change'
        }
      ],
      organizationTypeRule: [
        {
          required: true,
          validator() {
            if (!organizationTypeValue.value) {
              return new Error('Please select and option.');
            }
            return true;
          },
          trigger: 'change'
        }
      ],
      zipCodeRule: [
        {
          required: true,
          validator() {
            if (!zipCodeRef.value) {
              return new Error('Please enter a zip code.');
            }
            if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCodeRef.value)) {
              return new Error('Please enter a valid zip code.');
            }
            return true;
          },
          trigger: 'change'
        }
      ],
      participationInNetworkRule: [
        {
          required: true,
          validator() {
            if (!participationInNetworkValue.value) {
              return new Error('Please select an option.');
            }
            return true;
          },
          trigger: 'change'
        }
      ]
    };

    const validate = (): Promise<boolean> => {
      return new Promise((resolve) => {
        if (formRef.value) {
          formRef.value
            .validate((errors: Array<FormValidationError> | undefined) => {
              resolve(!errors?.length);
            })
            .catch(() => {
              resolve(false);
            });
        } else {
          resolve(false);
        }
      });
    };

    return {
      organizationTypeValue,
      participationInNetworkValue,
      nameRef,
      emailRef,
      roleRef,
      organizationRef,
      zipCodeRef,
      participationInNetworkRef,
      formRef,
      rules,
      validate,
      participationInNetworkQuestionRef
    };
  }
});
