/* eslint-disable no-param-reassign */
// import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex';
import { Graph } from '@/types/graph';
import { NetworkType } from '@/types';

const defaultCompanies = [] as string[];
const defaultGraph: Graph = {
  nodes: [],
  links: []
};
const defaultSurveyType = NetworkType.OrganizationOnly;

export default createStore({
  strict: true,
  state: {
    graph: defaultGraph,
    companies: defaultCompanies,
    surveyType: defaultSurveyType
  },
  mutations: {
    UPDATE_GRAPH(state, payload) {
      state.graph = payload;
    },
    UPDATE_SURVEY_TYPE(state, payload) {
      state.surveyType = payload;
    },
    SET_DEFAULTS(state) {
      state.companies = defaultCompanies;
      state.graph = defaultGraph;
      state.surveyType = defaultSurveyType;
    },
    SET_EMPTY_GRAPH(state) {
      state.graph = {
        nodes: [],
        links: []
      };
    }
  },
  actions: {
    // removeAllData(context) {
    //   context.commit('CLEAR_LOCAL_STORAGE');
    //   context.commit('SET_EMPTY_GRAPH');
    // },
    // resetDemoData(context) {
    //   context.commit('CLEAR_LOCAL_STORAGE');
    //   context.commit('SET_DEFAULTS');
    // },
    // createRandomData(context) {
    //   context.commit('CLEAR_LOCAL_STORAGE');
    //   context.commit('SET_RANDOM_GRAPH');
    // },
    updateGraph(context, payload) {
      context.commit('UPDATE_GRAPH', payload);
    },
    updateSurveyType(context, payload) {
      context.commit('UPDATE_SURVEY_TYPE', payload);
    }
    // updateCompanies(context, payload) {
    //   context.commit('UPDATE_COMPANIES', payload);
    // }
  }
});
