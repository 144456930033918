import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_n_layout_header = _resolveComponent("n-layout-header")!
  const _component_error = _resolveComponent("error")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_layout_content = _resolveComponent("n-layout-content")!
  const _component_n_layout_footer = _resolveComponent("n-layout-footer")!
  const _component_n_layout = _resolveComponent("n-layout")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, { "theme-overrides": _ctx.themeOverrides }, {
    default: _withCtx(() => [
      _createVNode(_component_n_loading_bar_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_dialog_provider, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_message_provider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_n_layout, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_n_layout_header, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Header)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_n_layout_content, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createVNode(_component_error),
                            _createVNode(_component_router_view)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_n_layout_footer, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, "© " + _toDisplayString(new Date().getFullYear()) + " The MiSTEM Network", 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["theme-overrides"]))
}