
import { defineComponent } from 'vue';
import { useVModel } from 'vue-composable';

export default defineComponent({
  name: 'SocialGraphLegend',
  props: {
    colorList: {
      type: Array as () => { key: number; label: string }[],
      required: true
    },
    nodeList: {
      type: Array as () => { key: number; label: string }[],
      required: true
    }
  },
  setup(props) {
    const colors = useVModel(props, 'colorList');
    const nodes = useVModel(props, 'nodeList');

    return {
      colors,
      nodes
    };
  }
});
