import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c2fd0f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remove" }
const _hoisted_2 = { class: "add" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_color_picker = _resolveComponent("n-color-picker")!
  const _component_remove_icon = _resolveComponent("remove-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_create_icon = _resolveComponent("create-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_text, { italic: "" }, {
      default: _withCtx(() => [
        _createTextVNode("Options")
      ]),
      _: 1
    }),
    _createVNode(_component_n_space, null, {
      default: _withCtx(() => [
        _createVNode(_component_n_form_item, {
          path: _ctx.columnOnePath,
          label: _ctx.columnOneLabel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_space, { vertical: "" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnOneModelValueRef, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: option.value as string,
                    style: {"display":"flex","align-items":"center"}
                  }, [
                    _createVNode(_component_n_input, {
                      value: option.label,
                      "onUpdate:value": ($event: any) => ((option.label) = $event),
                      type: "text",
                      placeholder: "Option Name",
                      style: {"width":"457px"}
                    }, null, 8, ["value", "onUpdate:value"])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["path", "label"]),
        _createVNode(_component_n_form_item, {
          path: _ctx.columnTwoPath,
          label: _ctx.columnTwoLabel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_space, { vertical: "" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnTwoModelValueRef, (option) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: option.value as string,
                    style: {"display":"flex","align-items":"center"}
                  }, [
                    _createVNode(_component_n_color_picker, {
                      value: option.label,
                      "onUpdate:value": ($event: any) => ((option.label) = $event),
                      modes: ['hex'],
                      style: {"width":"460px"}
                    }, null, 8, ["value", "onUpdate:value"]),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_n_button, {
                        circle: "",
                        onClick: ($event: any) => (_ctx.onRemove(_ctx.columnTwoModelValueRef.indexOf(option))),
                        title: "remove option"
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_n_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_remove_icon)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["path", "label"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_n_button, {
        circle: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCreate())),
        title: "add option"
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_n_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_create_icon)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}