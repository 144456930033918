
import { inject } from 'vue';
import { InjectionKeys } from '@/constants';

export default {
  name: 'Profile',
  setup() {
    const auth = inject(InjectionKeys.Auth);
    return {
      ...auth?.state
    };
  }
};
