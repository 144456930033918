
import { computed, defineComponent, reactive, ref, toRefs, watch } from 'vue';
import { CheckmarkCircleOutline as ValidIcon, CloseCircleOutline as InvalidIcon } from '@vicons/ionicons5';
import { useVModel } from 'vue-composable';
import { DateTime } from 'luxon';
import responseService from '@/services/response-service';

type ModalState = {
  emails: string;
};

export default defineComponent({
  name: 'SendModal',
  components: { ValidIcon, InvalidIcon },
  props: {
    surveyId: {
      type: String,
      required: true
    },
    surveyName: {
      type: String,
      required: true
    },
    showModal: {
      type: Boolean
    }
  },
  setup: (props) => {
    const showModalRef = useVModel(props, 'showModal');
    const surveyNameRef = useVModel(props, 'surveyName');
    const surveyId = useVModel(props, 'surveyId');
    const validEmailRef = ref(false);

    const modalState: ModalState = reactive({
      emails: ''
    });

    const emailList = computed(() => {
      return modalState.emails
        .split(/[\n,; ]/g)
        .map((email) => email?.trim())
        .filter(Boolean)
        .filter((value, index, self) => self.indexOf(value) === index);
    });

    const invalidEmails = () => {
      const invalidEmailList = modalState.emails
        .split(/[\n,; ]+/g)
        .map((email) => email?.trim())
        .filter(Boolean)
        .some((email) => !RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/).test(email));

      validEmailRef.value = !invalidEmailList;
    };

    const onPositiveClick = async () => {
      await Promise.all(
        emailList.value.map((email) =>
          responseService.create(surveyId.value, {
            surveyId: surveyId.value,
            email,
            createdOn: DateTime.now().toISO(),
            sentOn: DateTime.now().toISO()
          })
        )
      );

      window.location.reload();
    };

    watch(
      () => modalState.emails,
      () => invalidEmails()
    );

    return {
      ...toRefs(modalState),
      emailList,
      showModalRef,
      validEmailRef,
      surveyNameRef,
      onPositiveClick
    };
  }
});
