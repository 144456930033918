import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2713ebcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-content" }
const _hoisted_2 = { class: "remove" }
const _hoisted_3 = { class: "add" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_remove_icon = _resolveComponent("remove-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_create_icon = _resolveComponent("create-icon")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    path: _ctx.path,
    label: _ctx.label,
    class: "form-item"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_n_text, { italic: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Options")
          ]),
          _: 1
        }),
        _createVNode(_component_n_space, { vertical: "" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValueRef, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option.value as string,
                style: {"display":"flex","align-items":"center"}
              }, [
                _createVNode(_component_n_input, {
                  value: option.label,
                  "onUpdate:value": ($event: any) => ((option.label) = $event),
                  type: "text",
                  placeholder: "Option Name"
                }, null, 8, ["value", "onUpdate:value"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_n_button, {
                    circle: "",
                    onClick: ($event: any) => (_ctx.onRemove(_ctx.modelValueRef.indexOf(option))),
                    title: "remove option"
                  }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_n_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_remove_icon)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ]))
            }), 128))
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_n_button, {
            circle: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCreate())),
            title: "add option"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_n_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_create_icon)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["path", "label"]))
}