import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29d7d4ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "legend" }
const _hoisted_2 = { class: "legend-color-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h2 = _resolveComponent("n-h2")!
  const _component_n_collapse_item = _resolveComponent("n-collapse-item")!
  const _component_n_collapse = _resolveComponent("n-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_collapse, { "default-expanded-names": ['legend'] }, {
      default: _withCtx(() => [
        _createVNode(_component_n_collapse_item, { name: "legend" }, {
          header: _withCtx(() => [
            _createVNode(_component_n_h2, null, {
              default: _withCtx(() => [
                _createTextVNode("Legend")
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: color.key,
                  class: "legend-entry"
                }, [
                  _createElementVNode("span", {
                    class: "legend-color-box",
                    style: _normalizeStyle('background-color:' + color.label)
                  }, null, 4),
                  _createElementVNode("span", null, _toDisplayString(_ctx.nodes.find((node) => node.key === color.key)?.label), 1)
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}