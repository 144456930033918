
import { defineComponent } from 'vue';
import UserAdminList from '@/components/admin/UserAdminList.vue';

export default defineComponent({
  name: 'Admin',
  components: { UserAdminList },
  setup() {
    return {};
  }
});
