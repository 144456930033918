import axiosInstance from './api';
import { useAuth0 } from '@/auth';

// https://www.bezkoder.com/vue-3-refresh-token/

const setup = async (): Promise<void> => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const auth = useAuth0();
      const token = await auth.getTokenSilently();

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`; // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // TODO handle the token refresh
  // axiosInstance.interceptors.response.use(
  //   (res) => {
  //     return res;
  //   },
  //   async (err) => {
  //     const originalConfig = err.config;

  //     if (originalConfig.url !== '/auth/signin' && err.response) {
  //       // Access Token was expired
  //       if (err.response.status === 401 && !originalConfig._retry) {
  //         originalConfig._retry = true;

  //         try {
  //           const rs = await axiosInstance.post('/auth/refreshtoken', {
  //             refreshToken: TokenService.getLocalRefreshToken()
  //           });

  //           const { accessToken } = rs.data;

  //           store.dispatch('auth/refreshToken', accessToken);
  //           TokenService.updateLocalAccessToken(accessToken);

  //           return axiosInstance(originalConfig);
  //         } catch (_error) {
  //           return Promise.reject(_error);
  //         }
  //       }
  //     }

  //     return Promise.reject(err);
  //   }
  // );
};

export default setup;
