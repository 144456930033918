
import { inject, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMessage } from 'naive-ui';
import { LogOutOutline as LogoutIcon, DocumentOutline as SurveyIcon, LocationOutline as RegionIcon, PersonOutline as UserIcon } from '@vicons/ionicons5';
import { InjectionKeys } from '@/constants';
import { Auth0Manager } from '@/auth';

export default {
  name: 'NavBar',
  components: { LogoutIcon, SurveyIcon, RegionIcon, UserIcon },
  setup() {
    const auth = inject(InjectionKeys.Auth) as Auth0Manager;
    const router = useRouter();
    const message = useMessage();
    const isMenuVisible = ref(false);
    const homeUrl = computed((): string => {
      return auth.state.isAuthenticated ? '/admin' : '/';
    });

    const login = () => {
      auth.loginWithRedirect({
        appState: { targetUrl: '/admin' }
      });
    };

    const logout = () => {
      message.info('Logging out...');
      auth.logout({ returnTo: window.location.origin });
      router.push({ path: '/' });
    };

    const toggleMenu = () => {
      isMenuVisible.value = !isMenuVisible.value;
    };

    const handleMouseLeave = () => {
      isMenuVisible.value = false;
    };

    return {
      ...auth.state,
      homeUrl,
      login,
      logout,
      isMenuVisible,
      toggleMenu,
      handleMouseLeave
    };
  }
};
