export const createIdentifierFromStrings = (strings: string[]): string => {
  let identifier = '';

  strings.forEach((string) => {
    const cleanString = string.toLowerCase().replace(/\s/g, '-');
    if (strings.indexOf(string) === strings.length - 1) {
      identifier += cleanString;
    } else {
      identifier += `${cleanString}|`;
    }
  });
  return identifier;
};
