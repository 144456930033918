import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyComponent = _resolveComponent("SurveyComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SurveyComponent, {
      takeGuid: _ctx.takeGuid,
      userId: _ctx.userId
    }, null, 8, ["takeGuid", "userId"])
  ]))
}