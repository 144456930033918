
import { defineComponent, PropType } from 'vue';
import { SelectOption } from 'naive-ui';
import { AddSharp as CreateIcon, RemoveOutline as RemoveIcon } from '@vicons/ionicons5';
import { useVModel } from 'vue-composable';

export default defineComponent({
  name: 'OptionsEditor',
  components: { CreateIcon, RemoveIcon },
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object as PropType<SelectOption[]>,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const modelValueRef = useVModel(props, 'modelValue');
    const onCreate = () => {
      const newIndex = props.modelValue.length + 1;
      modelValueRef.value = [
        ...props.modelValue,
        {
          label: `Option ${newIndex}`,
          key: `${newIndex}`
        }
      ];
    };

    const onRemove = (index: number) => {
      if (index !== -1) {
        modelValueRef.value = props.modelValue.filter((_, i) => i !== index);
      }
    };
    return {
      onCreate,
      onRemove,
      modelValueRef
    };
  }
});
