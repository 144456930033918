import { Graph, Link, Node, Response } from '@/types';
import { createIdentifierFromStrings } from './identifierUtils';

export const createIdentifier = (name: string, organization: string): string => {
  return `${name.replace(' ', '')}-${organization.replace(' ', '')}`;
};

const createPersonNodesAndLinks = (responses: Response[], nodeColors: { key: number; label: string }[]): Graph => {
  const nodes: Node[] = [];
  const links: Link[] = [];

  responses.forEach((response) => {
    // const respondentNodeId = createIdentifier(response.info.name, response.info.organization);
    const respondentNodeId = createIdentifierFromStrings([
      response.info.cleanName ?? '',
      response.info.cleanOrganization ?? '',
      response.info.cleanOrganizationType?.toString() ?? ''
    ]);
    if (!nodes.some((node) => node.id === respondentNodeId)) {
      nodes.push({
        id: respondentNodeId,
        name: response.info.cleanName ?? '',
        detail: [response.info.cleanOrganization ?? ''],
        color: nodeColors.find((color) => color.key === response.info.cleanOrganizationType)?.label as string
      });
    }
    response.network.yourNetwork.forEach((yourNetworkPerson) => {
      // const personIdentifier = createIdentifier(yourNetworkPerson.cleanName ?? '', yourNetworkPerson.cleanOrganization ?? '');
      const personIdentifier = createIdentifierFromStrings([
        yourNetworkPerson.cleanName ?? '',
        yourNetworkPerson.cleanOrganization ?? '',
        yourNetworkPerson.cleanOrganizationType?.toString() ?? ''
      ]);
      if (!nodes.some((node) => node.id === personIdentifier)) {
        nodes.push({
          id: personIdentifier,
          name: yourNetworkPerson.cleanName ?? '',
          detail: [yourNetworkPerson.cleanOrganization ?? ''],
          color: nodeColors.find((color) => color.key === yourNetworkPerson.cleanOrganizationType)?.label as string
        });
      }
      links.push({
        source: respondentNodeId,
        target: personIdentifier
      });
    });
  });
  return { nodes, links };
};

const createOrganizationNodesAndLinks = (responses: Response[], nodeColors: { key: number; label: string }[]): Graph => {
  const nodes: Node[] = [];
  const links: Link[] = [];

  const orgNodesMap = new Map<string, { id: string; name: string; members: Set<string>; color: string }>();

  responses.forEach((response) => {
    const respondentOrgId = createIdentifierFromStrings([response.info.cleanOrganization ?? '', response.info.cleanOrganizationType?.toString() ?? '']);

    if (!orgNodesMap.has(respondentOrgId)) {
      orgNodesMap.set(respondentOrgId, {
        id: respondentOrgId,
        name: response.info.cleanOrganization ?? '',
        members: new Set<string>([response.info.cleanName ?? '']),
        color: nodeColors.find((color) => color.key === response.info.cleanOrganizationType)?.label as string
      });
    } else {
      orgNodesMap.get(respondentOrgId)!.members.add(response.info.cleanName ?? '');
    }

    response.network.yourNetwork.forEach((networkPerson) => {
      const networkOrgId = createIdentifierFromStrings([networkPerson.cleanOrganization ?? '', networkPerson.cleanOrganizationType?.toString() ?? '']);

      if (!orgNodesMap.has(networkOrgId)) {
        orgNodesMap.set(networkOrgId, {
          id: networkOrgId,
          name: networkPerson.cleanOrganization ?? '',
          members: new Set<string>(),
          color: nodeColors.find((color) => color.key === networkPerson.cleanOrganizationType)?.label as string
        });
        orgNodesMap.get(networkOrgId)!.members.add(networkPerson.cleanName ?? '');
      } else {
        orgNodesMap.get(networkOrgId)!.members.add(networkPerson.cleanName ?? '');
      }

      const linkSource = orgNodesMap.get(respondentOrgId)?.id;
      const linkTarget = orgNodesMap.get(networkOrgId)?.id;

      if (linkSource && linkTarget && linkSource !== linkTarget) {
        links.push({ source: linkSource, target: linkTarget });
      }
    });
  });

  nodes.push(
    ...Array.from(orgNodesMap.values()).map((org) => ({
      id: org.id,
      name: org.name,
      detail: Array.from(org.members),
      color: org.color
    }))
  );

  return { nodes, links };
};

export const convertResponsesToGraphData = (responses: Response[], viewOption: string, nodeColors: { key: number; label: string }[]): Graph => {
  if (viewOption === 'person') {
    return createPersonNodesAndLinks(responses, nodeColors);
  }
  if (viewOption === 'organization') {
    return createOrganizationNodesAndLinks(responses, nodeColors);
  }

  return { nodes: [], links: [] };
};
