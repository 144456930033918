
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Survey',
  methods: {
    close() {
      this.$router.push('/');
    }
  }
});
