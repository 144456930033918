import { Parser } from 'json2csv';
import { DateTime } from 'luxon';
import responseService from '@/services/response-service';
import { Survey } from '@/types';

export const downloadCSVData = async (currentSurvey: Survey): Promise<void> => {
  const responses = await responseService.getList(currentSurvey.id);
  const completedResponses = responses.filter((r) => r.completedOn !== null);

  const typeTextReducer = (arr: { key: number; label: string }[] | undefined): { [key: number]: string } | undefined =>
    arr?.reduce(
      (accumulator, orgType) => ({
        ...accumulator,
        [orgType.key]: orgType.label
      }),
      {}
    );

  const orgTypeText = typeTextReducer(currentSurvey?.organizationTypeList);
  const networkTypeText = typeTextReducer(currentSurvey?.participationNetworkTypeList);
  const networkInteractionsTypeText = typeTextReducer(currentSurvey?.questions.interactions.options);

  interface RelationshipKeys {
    [key: `relationshipKey${number}`]: number;
  }

  const networkRelationshipsKeys: RelationshipKeys = {
    relationshipKey1: 0,
    relationshipKey2: 0,
    relationshipKey3: 0,
    relationshipKey4: 0,
    relationshipKey5: 0,
    relationshipKey6: 0,
    relationshipKey7: 0,
    relationshipKey8: 0,
    relationshipKey9: 0,
    relationshipKey10: 0
  };

  const flattenedResponses = completedResponses.flatMap((response) => {
    const yourProfile = response;
    const yourProfileInfo = response.info;

    return yourProfile.network.yourNetwork.map((person) => ({
      name: yourProfileInfo.name,
      email: yourProfileInfo.email,
      role: yourProfileInfo.role,
      organization: yourProfileInfo.organization,
      organizationType: orgTypeText?.[yourProfileInfo.organizationType as number],
      zipCode: yourProfileInfo.zipCode,
      participationInNetworkType: networkTypeText?.[yourProfileInfo.participationInNetworkType as number],
      networkContactName: person.name,
      networkContactOrganization: person.organization,
      networkInteractions: networkInteractionsTypeText?.[person.interactionKey],
      ...networkRelationshipsKeys,
      ...person.relationshipKeys.reduce(
        (accumulator, key) => ({
          ...accumulator,
          [`relationshipKey${key}`]: 1
        }),
        {}
      )
    }));
  });

  const fields = [
    {
      label: 'Name',
      value: 'name'
    },
    {
      label: 'Email',
      value: 'email'
    },
    {
      label: 'Role',
      value: 'role'
    },
    {
      label: 'Organization',
      value: 'organization'
    },
    {
      label: 'Organization Type',
      value: 'organizationType'
    },
    {
      label: 'Zip Code',
      value: 'zipCode'
    },
    {
      label: 'Participation in Network Type',
      value: 'participationInNetworkType'
    },
    {
      label: 'My Network Contact',
      value: 'networkContactName'
    },
    {
      label: 'My Network Contact Organization',
      value: 'networkContactOrganization'
    },
    ...(currentSurvey
      ? currentSurvey.questions.relationships.options.map((option, index) => ({
          label: option.label,
          value: `relationshipKey${index + 1}`
        }))
      : []),
    {
      label: 'My Network Interactions',
      value: 'networkInteractions'
    }
  ];

  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(flattenedResponses);

  const anchor = document.createElement('a');
  anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  anchor.target = '_blank';
  anchor.download = `${currentSurvey.name}-${DateTime.now().toISODate()}.csv`;
  anchor.click();
};
