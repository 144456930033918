import api from './api';
import publicApi from './public-api';
import { Region } from '@/types';

const rootPath = '/regions';

export default {
  getList: async (): Promise<Region[]> => {
    return api.get(rootPath).then(({ data }) => data);
  },
  get: (id: string): Promise<Region> => {
    return publicApi.get(`${rootPath}/${id}`).then(({ data }) => data);
  },
  getDefault: (): Promise<Region> => {
    return api.get(`${rootPath}/default`).then(({ data }) => data);
  },
  create: (region: Region): Promise<Region> => {
    return api.post(rootPath, region).then(({ data }) => data);
  },
  update: (region: Region): Promise<Region> => {
    return api.put(`${rootPath}/${region.id}`, region).then(({ data }) => data);
  },
  delete: (id: string): Promise<void> => {
    return api.delete(`${rootPath}/${id}`);
  },
  getUserRegions: (): Promise<Region[]> => {
    return api.get(`/user${rootPath}`).then(({ data }) => data);
  }
};
