
import { defineComponent, reactive, ref, watch } from 'vue';
import { useVModel } from 'vue-composable';
import { useMessage } from 'naive-ui';
import regionService from '@/services/region-service';
import { Region, User } from '@/types';

export default defineComponent({
  name: 'RegionEditModel',
  props: {
    region: {
      type: Object as () => Region,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    regionalManagers: {
      type: Array as () => User[],
      required: true
    }
  },
  setup(props, { emit }) {
    const showModalRef = useVModel(props, 'showModal');

    const state = reactive({
      editableRegion: { ...props.region },
      selectedManagerIds: [] as string[],
      managerOptions: [] as { value: string; label: string }[]
    });
    const isNameValid = ref(true);
    const message = useMessage();

    const resetRegion = () => {
      state.selectedManagerIds = [];
    };

    const closeModal = () => {
      resetRegion();
      showModalRef.value = false;
    };

    const loadManagerOptions = async () => {
      state.managerOptions = props.regionalManagers.map((manager) => ({
        value: manager.id,
        label: manager.name === manager.email ? manager.name : `${manager.name} (${manager.email})`
      }));

      if (state.editableRegion.id) {
        state.selectedManagerIds = props.regionalManagers
          .filter((manager) => manager.regions.map((region) => region.id).includes(state.editableRegion.id))
          .map((manager) => manager.id);
      } else {
        state.selectedManagerIds = [];
      }
    };

    const validateName = () => {
      isNameValid.value = state.editableRegion.name.trim().length > 0;
      return isNameValid.value;
    };

    const handleUpdate = () => {
      if (!validateName()) return;

      state.editableRegion.managerIds = state.selectedManagerIds;

      if (state.editableRegion.id) {
        regionService
          .update(state.editableRegion)
          .then((data) => {
            emit('region-update');
            state.editableRegion = data;
            state.selectedManagerIds = data.managerIds;
            message.success('Region updated');
            closeModal();
          })
          .catch((error) => {
            state.editableRegion = { ...props.region };
            const errorMessage = error.response.data;
            message.error(errorMessage || 'Failed to update region');
          });
      } else {
        regionService
          .create(state.editableRegion)
          .then((data) => {
            emit('region-update');
            state.editableRegion = data;
            state.selectedManagerIds = data.managerIds;
            message.success('Region created');
            closeModal();
          })
          .catch((error) => {
            const errorMessage = error.response.data;
            message.error(errorMessage || 'Failed to create region');
          });
      }
    };

    watch(
      () => props.showModal,
      async (newVal) => {
        if (newVal) {
          state.selectedManagerIds = [];
          await loadManagerOptions();
        }
      }
    );

    watch(
      () => props.region,
      (newVal) => {
        Object.assign(state.editableRegion, newVal);
      }
    );

    return {
      handleUpdate,
      showModalRef,
      isNameValid,
      closeModal,
      state
    };
  }
});
