
import { defineComponent } from 'vue';
import SurveyAdminList from '@/components/admin/SurveyAdminList.vue';

export default defineComponent({
  name: 'Admin',
  components: { SurveyAdminList },
  setup() {
    return {};
  }
});
