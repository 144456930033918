
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useMessage } from 'naive-ui';
import { useStore } from 'vuex';
import { NetworkType, Survey } from '../../types/survey';
import responseService from '@/services/response-service';
import { GraphService } from '@/services/graph-service';
import { Response } from '@/types';
import { convertResponsesToGraphData } from '@/utils/graph-helpers';
import SocialGraphSideBar from './SocialGraphSideBar.vue';
import SocialGraphLegend from './SocialGraphLegend.vue';

export default defineComponent({
  name: 'SocialGraph',
  props: {
    survey: {
      type: Object as () => Survey | null,
      required: true
    },
    isPublic: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SocialGraphSideBar,
    SocialGraphLegend
  },
  setup(props) {
    const store = useStore();
    const graph = computed(() => store.state.graph);
    const graphRef = ref();
    const svgRef = ref();
    const responsesRef = ref<Response[]>([]);
    const message = useMessage();
    const surveyRef = ref<Survey | null>(props.survey);
    const isPublicRef = ref<boolean>(props.isPublic);
    const viewOptionRef = ref<string>('organization');

    const fetchResponses = async () => {
      try {
        const responses = isPublicRef.value
          ? await responseService.getPublicList(props.survey?.shareResultsGUID as string)
          : await responseService.getList(props.survey?.id as string);
        return responses;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        message.error(error.message);
        return [];
      }
    };

    const networkTypeRef = ref<NetworkType>(surveyRef.value?.questions.network.networkType ?? NetworkType.OrganizationOnly);

    const graphService = new GraphService(graph, svgRef, graphRef, networkTypeRef);

    const setViewOption = (viewOption: string) => {
      viewOptionRef.value = viewOption;
    };

    watch(graph, () => {
      graphService.renderGraph();
    });

    watch(
      () => viewOptionRef.value,
      (newVal) => {
        const graphData = convertResponsesToGraphData(responsesRef.value, newVal, surveyRef.value?.nodeColors ?? []);
        store.dispatch('updateGraph', graphData);
      }
    );

    watch(
      () => props.survey,
      async (newVal) => {
        if (newVal) {
          surveyRef.value = newVal;
          responsesRef.value = await fetchResponses();
          const graphData = convertResponsesToGraphData(responsesRef.value, viewOptionRef.value, surveyRef.value?.nodeColors ?? []);

          store.dispatch('updateSurveyType', surveyRef.value?.questions.network.networkType);
          store.dispatch('updateGraph', graphData);

          graphService.renderGraph();
        }
      },
      { deep: true }
    );

    onMounted(() => {
      if (props.survey) {
        fetchResponses().then((responses) => {
          responsesRef.value = responses;
          const graphData = convertResponsesToGraphData(responsesRef.value, viewOptionRef.value, surveyRef.value?.nodeColors ?? []);
          store.dispatch('updateGraph', graphData);

          graphService.renderGraph();
        });
      }
    });
    return {
      graphRef,
      svgRef,
      surveyRef,
      setViewOption,
      isPublicRef
    };
  }
});
