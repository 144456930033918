
import { defineComponent, reactive, ref, watch } from 'vue';

import { FormInst, useMessage } from 'naive-ui';
import { useVModel } from 'vue-composable';
import { User, RegionDTO } from '@/types';
import userService from '@/services/user-service';

export default defineComponent({
  name: 'UserEditModal',
  props: {
    user: {
      type: Object as () => User | null,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    regionOptions: {
      type: Array as () => Array<{ value: string; label: string }>,
      required: true
    }
  },
  setup(props, { emit }) {
    const showModalRef = useVModel(props, 'showModal');
    const formRef = ref<FormInst | null>(null);
    const formModel = reactive({
      id: '',
      name: '',
      email: '',
      role: '',
      regions: [] as string[]
    });

    const rules = {
      name: [{ required: true, message: 'Name is required', trigger: ['blur', 'change'] }],
      email: [{ required: true, message: 'Email is required', trigger: ['blur', 'change'] }],
      role: [{ required: true, message: 'Role is required', trigger: ['blur', 'change'] }],
      regions: [{ required: true, message: 'At least one region is required', trigger: ['blur', 'change'], validator: () => formModel.regions.length > 0 }]
    };
    const message = useMessage();
    const roles = [
      { value: 'Admin', label: 'Admin' },
      { value: 'Regional Manager', label: 'Regional Manager' }
    ];

    watch(
      () => props.user,
      (newUser) => {
        if (newUser) {
          formModel.id = newUser.id;
          formModel.name = newUser.name;
          formModel.email = newUser.email;
          formModel.role = newUser.role;
          formModel.regions = newUser.regions.map((region) => region.id);
        }
      },
      { immediate: true }
    );
    watch(
      () => formModel.role,
      (newRole) => {
        if (newRole === 'Admin') {
          formModel.regions = [];
        }
      }
    );
    const resetFormModel = () => {
      formModel.id = props.user?.id || '';
      formModel.name = props.user?.name || '';
      formModel.email = props.user?.email || '';
      formModel.role = props.user?.role || '';
      formModel.regions = props.user?.regions.map((region) => region.id) || [];
    };
    const closeModal = () => {
      resetFormModel();
      showModalRef.value = false;
    };

    const saveUser = async () => {
      await formRef.value?.validate(async (errors) => {
        if (!errors) {
          try {
            if (props.user?.id !== 'new') {
              const updatedUser = {
                ...formModel,
                regions: formModel.regions.map((id) => {
                  return { id, name: props.regionOptions.find((option) => option.value === id)?.label || 'Unknown' };
                })
              };
              await userService.update(updatedUser).then((data) => {
                message.success('User updated');
                emit('user-updated', data);
              });
            } else {
              // New user, call create
              const newUser = { ...formModel, regions: formModel.regions.map((id) => ({ id })) as RegionDTO[] };
              await userService.create(newUser).then((data) => {
                message.success('User created');
                emit('user-created', data);
              });
            }
            closeModal();
          } catch {
            message.error('Error saving user');
          }
        }
      });
    };
    return { formRef, showModalRef, formModel, rules, saveUser, closeModal, roles, props, resetFormModel };
  }
});
