
import { defineComponent } from 'vue';
import { useVModel } from 'vue-composable';
import { useMessage } from 'naive-ui';
import { WarningOutline as WarningIcon } from '@vicons/ionicons5';
import surveyService from '@/services/survey-service';
import { Survey } from '@/types';

export default defineComponent({
  name: 'ConfirmsurveyDeleteModal',
  props: {
    survey: {
      type: Object as () => Survey | null,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  },
  components: { WarningIcon },
  setup(props, { emit }) {
    const showModalRef = useVModel(props, 'showModal');
    const message = useMessage();
    const confirmDelete = () => {
      if ((props.survey?.completedCount ?? 0) > 0) {
        message.error('Cannot delete a survey that has been completed');
        showModalRef.value = false;
      } else {
        surveyService
          .delete(props.survey?.id ?? '')
          .then(() => {
            emit('survey-delete', props.survey?.id ?? '');
          })
          .catch(() => {
            message.error('Failed to delete survey');
          })
          .finally(() => {
            showModalRef.value = false;
          });
      }
    };

    const cancelDelete = () => {
      showModalRef.value = false;
    };

    return {
      confirmDelete,
      cancelDelete,
      showModalRef
    };
  }
});
