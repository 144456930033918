import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Admin from '@/views/admin/Admin.vue';
import Regions from '@/views/admin/Regions.vue';
import Users from '@/views/admin/Users.vue';
import SurveyEdit from '@/views/admin/SurveyEdit.vue';
import SurveyResults from '@/views/shared/SurveyResults.vue';
import Profile from '@/views/Profile.vue';
import ExternalApi from '@/views/ExternalApi.vue';
import Survey from '@/views/survey/Survey.vue';
import surveyService from '@/services/survey-service';
import responseService from '@/services/response-service';
import { useAuth0 } from '@/auth';
import SurveyNotFound from '@/views/survey/SurveyNotFound.vue';
import DataCleanup from '@/views/survey/DataCleanup.vue';

const { routeGuard, adminGuard } = useAuth0();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/admin',
    component: Admin,
    beforeEnter: routeGuard
  },
  {
    path: '/admin/regions',
    component: Regions,
    beforeEnter: adminGuard
  },
  {
    path: '/admin/users',
    component: Users,
    beforeEnter: adminGuard
  },
  {
    path: '/admin/survey/:id',
    props: true,
    component: SurveyEdit,
    beforeEnter: routeGuard
  },
  {
    path: '/admin/survey/:surveyId/results',
    props: (route) => ({ surveyId: route.params.surveyId, isPublic: false }),
    component: SurveyResults,
    beforeEnter: routeGuard
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: routeGuard
  },
  {
    path: '/external-api',
    component: ExternalApi,
    beforeEnter: routeGuard
  },
  {
    path: '/survey-not-found',
    component: SurveyNotFound
  },
  {
    path: '/survey/:pathMatch(.*)*',
    component: SurveyNotFound,
    beforeEnter(to, from, next) {
      const auth = useAuth0();
      if (auth.state.isAuthenticated) {
        next('/admin');
      } else {
        next();
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/admin'
  },
  {
    path: '/survey/:takeGuid/:userId',
    props: true,
    component: Survey,
    beforeEnter(to, from, next) {
      const takeGuid = typeof to.params.takeGuid === 'string' ? to.params.takeGuid : to.params.takeGuid[0];
      const userId = typeof to.params.userId === 'string' ? to.params.userId : to.params.userId[0];

      surveyService
        .getSurveyToTake(takeGuid)
        .then((survey) => {
          if (!survey) {
            next(from.path);
            return;
          }
          responseService
            .get(userId)
            .then((response) => {
              if (response && response.surveyId === survey.id) {
                next();
              } else {
                next(from.path);
              }
            })
            .catch(() => next(from.path));
        })
        .catch(() => next(from.path));
    }
  },
  {
    path: '/survey/:shareGuid/results',
    props: (route) => ({ surveyId: route.params.shareGuid, isPublic: true }),
    component: SurveyResults
  },
  {
    path: '/admin/survey/:surveyId/cleanup',
    props: true,
    beforeEnter: adminGuard,
    component: DataCleanup
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
