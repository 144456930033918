
import { defineComponent, computed } from 'vue';
import { useVModel } from 'vue-composable';
import { ChevronForward as ChevronRight, ChevronBack as ChevronLeft } from '@vicons/ionicons5';
import { useMessage } from 'naive-ui';

export default defineComponent({
  name: 'SurveyButtons',
  components: { ChevronRight, ChevronLeft },
  emits: ['backClick', 'continueClick', 'finishClick'],
  props: {
    backButton: {
      type: Boolean,
      default: true
    },
    continueButton: {
      type: Boolean,
      default: true
    },
    finishButton: {
      type: Boolean,
      default: false
    },
    hasAgreed: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const backButtonRef = useVModel(props, 'backButton');
    const continueButtonRef = useVModel(props, 'continueButton');
    const finishButtonRef = useVModel(props, 'finishButton');
    const message = useMessage();
    const hasAgreedRef = computed(() => props.hasAgreed);

    const handlePreviousClick = () => {
      emit('backClick');
    };

    const handleContinueClick = () => {
      if (!hasAgreedRef.value) {
        message.error('You must agree to participate to continue');
        return;
      }
      emit('continueClick');
    };

    const handleFinishClick = () => {
      if (!hasAgreedRef.value) {
        message.error('You must agree to participate to continue');
        return;
      }
      emit('finishClick');
    };

    return {
      handleContinueClick,
      handlePreviousClick,
      handleFinishClick,
      backButtonRef,
      continueButtonRef,
      finishButtonRef,
      hasAgreedRef
    };
  }
});
