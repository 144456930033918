import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: _ctx.showModalRef,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModalRef) = $event)),
    preset: _ctx.modalType || 'card',
    "block-scroll": false,
    "mask-closable": false,
    title: _ctx.modalTitle,
    style: _normalizeStyle({ width: _ctx.modalWidth || '35%' }),
    "positive-text": "Confirm",
    "negative-text": "Cancel",
    onClose: _ctx.modalOnNegativeClick,
    onPositiveClick: _ctx.modalOnPositiveClick,
    onNegativeClick: _ctx.modalOnNegativeClick
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalContent), _normalizeProps(_guardReactiveProps(_ctx.modalContentProps)), null, 16))
    ]),
    _: 1
  }, 8, ["show", "preset", "title", "style", "onClose", "onPositiveClick", "onNegativeClick"]))
}