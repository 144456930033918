import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wide-content survey-edit form admin"
}
const _hoisted_2 = { style: {"font-size":"1rem"} }
const _hoisted_3 = { class: "tab-selection-buttons" }
const _hoisted_4 = { class: "tab-progress-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_alert = _resolveComponent("n-alert")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_h2 = _resolveComponent("n-h2")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_n_form = _resolveComponent("n-form")!
  const _component_n_tab_pane = _resolveComponent("n-tab-pane")!
  const _component_n_form_item_gi = _resolveComponent("n-form-item-gi")!
  const _component_n_grid = _resolveComponent("n-grid")!
  const _component_OptionsEditor = _resolveComponent("OptionsEditor")!
  const _component_TwoColumnOptionsEditor = _resolveComponent("TwoColumnOptionsEditor")!
  const _component_WeightedOptionsEditor = _resolveComponent("WeightedOptionsEditor")!
  const _component_n_tabs = _resolveComponent("n-tabs")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_ctx.loaded && _ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.data.completedCount > 0 || _ctx.data.sentCount > 0 || _ctx.data.viewCount > 0)
          ? (_openBlock(), _createBlock(_component_n_alert, {
              key: 0,
              type: "warning"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.warningMessage), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_n_tabs, {
          "default-value": "details",
          size: "large",
          "justify-content": "center",
          type: "line",
          ref: "tabsInstRef",
          value: _ctx.valueRef,
          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.valueRef) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_tab_pane, {
              name: _ctx.CREATE_SURVEY_TABS[0],
              tab: "Details"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_form, {
                  ref: "formRef",
                  model: _ctx.data
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_n_form_item, {
                      path: "name",
                      label: "Survey Title"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_n_input, {
                          type: "text",
                          value: _ctx.data.name,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.name) = $event)),
                          placeholder: "Survey Title"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    (_ctx.regionOptions.length > 1)
                      ? (_openBlock(), _createBlock(_component_n_form_item, {
                          key: 0,
                          path: "region",
                          label: "Survey Region"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_n_select, {
                              status: _ctx.isValidRegion ? 'default' : 'error',
                              onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateRegion())),
                              label: "Region",
                              placeholder: "Select",
                              options: _ctx.regionOptions,
                              value: _ctx.selectedRegion,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRegion) = $event)),
                              filterable: ""
                            }, null, 8, ["status", "options", "value"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (!_ctx.isValidRegion)
                      ? (_openBlock(), _createBlock(_component_n_alert, {
                          key: 1,
                          type: "error"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Region is required")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_n_h2, { class: "section-header" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Landing Page Information")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TextArea, {
                      label: "Introduction",
                      path: "details.introduction",
                      modelValue: _ctx.data.details.introduction,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.details.introduction) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_TextArea, {
                      label: "Participation",
                      path: "details.participation",
                      modelValue: _ctx.data.details.participation,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.details.participation) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_TextArea, {
                      label: "Benefits",
                      path: "details.benefits",
                      modelValue: _ctx.data.details.benefits,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.details.benefits) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_TextArea, {
                      label: "Confidentiality/Risks",
                      path: "details.confidentiality",
                      modelValue: _ctx.data.details.confidentiality,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.details.confidentiality) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_TextArea, {
                      label: "Questions",
                      path: "details.questions",
                      modelValue: _ctx.data.details.questions,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.details.questions) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            }, 8, ["name"]),
            _createVNode(_component_n_tab_pane, {
              name: _ctx.CREATE_SURVEY_TABS[1],
              tab: "Questions"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_form, {
                  ref: "formRef",
                  model: _ctx.data
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_n_h2, { class: "section-header" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Network")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TextArea, {
                      label: "Question",
                      path: "questions.network.text",
                      modelValue: _ctx.data.questions.network.text,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.questions.network.text) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_n_grid, {
                      span: 24,
                      "x-gap": 24,
                      class: "section-dropdown"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_n_form_item_gi, {
                          span: 12,
                          label: "Network Type",
                          path: "questions.network.networkType"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_n_select, {
                              label: "Test",
                              placeholder: "Select",
                              options: _ctx.networkTypeOptions,
                              value: _ctx.data.questions.network.networkType,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.data.questions.network.networkType) = $event))
                            }, null, 8, ["options", "value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_n_form_item_gi, {
                          span: 12,
                          label: "Maximum Entries",
                          path: "questions.network.maximumEntries"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_n_select, {
                              placeholder: "Select",
                              options: _ctx.maxEntriesOptions,
                              value: _ctx.data.questions.network.maximumEntries,
                              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data.questions.network.maximumEntries) = $event))
                            }, null, 8, ["options", "value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_n_h2, { class: "section-header" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Organization Type / Network Participation")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TextArea, {
                      label: "Network Participation Question",
                      path: "data.participationNetworkQuestion",
                      modelValue: _ctx.data.participationNetworkQuestion,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.data.participationNetworkQuestion) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_OptionsEditor, {
                      label: "Network Participation Types",
                      path: "data.participationNetworkOptions",
                      modelValue: _ctx.data.participationNetworkTypeList,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.data.participationNetworkTypeList) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_TwoColumnOptionsEditor, {
                      columnOneLabel: "Organization Types",
                      columnTwoLabel: "Node Colors",
                      columnOnePath: "data.organizationTypeList",
                      columnTwoPath: "data.nodeColors",
                      columnOneModelValue: _ctx.data.organizationTypeList,
                      "onUpdate:columnOneModelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.data.organizationTypeList) = $event)),
                      columnTwoModelValue: _ctx.data.nodeColors,
                      "onUpdate:columnTwoModelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.data.nodeColors) = $event))
                    }, null, 8, ["columnOneModelValue", "columnTwoModelValue"]),
                    _createVNode(_component_n_h2, { class: "section-header" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Relationships")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TextArea, {
                      label: "Question",
                      path: "questions.relationships.text",
                      modelValue: _ctx.data.questions.relationships.text,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.data.questions.relationships.text) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_WeightedOptionsEditor, {
                      label: "Options (Checkboxes: Allow multiple selections)",
                      path: "questions.relationships.options",
                      modelValue: _ctx.data.questions.relationships.options,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.data.questions.relationships.options) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_n_h2, { class: "section-header" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Interactions")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TextArea, {
                      label: "Question",
                      path: "questions.interactions.text",
                      modelValue: _ctx.data.questions.interactions.text,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.data.questions.interactions.text) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_WeightedOptionsEditor, {
                      label: "Options (Radio: Allow single selection)",
                      path: "questions.interactions.options",
                      modelValue: _ctx.data.questions.interactions.options,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.data.questions.interactions.options) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            }, 8, ["name"]),
            _createVNode(_component_n_tab_pane, {
              name: _ctx.CREATE_SURVEY_TABS[2],
              tab: "Email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_h2, { class: "section-header" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Email")
                  ]),
                  _: 1
                }),
                _createVNode(_component_n_form_item, {
                  path: "emailSubject",
                  label: "Subject"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_n_input, {
                      type: "text",
                      value: _ctx.data.emailSubject,
                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.data.emailSubject) = $event)),
                      placeholder: "Subject"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_TextArea, {
                  label: "Body",
                  path: "emailBody",
                  modelValue: _ctx.data.emailBody,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.data.emailBody) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["name"])
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(_component_n_space, { justify: "space-between" }, {
          default: _withCtx(() => [
            _createVNode(_component_n_button, {
              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.cancel()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.valueRef !== _ctx.CREATE_SURVEY_TABS[0])
                ? (_openBlock(), _createBlock(_component_n_button, {
                    key: 0,
                    class: "tab-back-button",
                    onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.showPrev())),
                    type: "default"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Back")
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.valueRef !== _ctx.CREATE_SURVEY_TABS[2] && !_ctx.data.id)
                  ? (_openBlock(), _createBlock(_component_n_button, {
                      key: 0,
                      onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.showNext())),
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Continue")
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_n_button, {
                      key: 1,
                      type: "primary",
                      onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.save()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Save")
                      ]),
                      _: 1
                    }))
              ])
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}