
import { computed, ComputedRef, defineComponent, onMounted, PropType, ref, watch, watchEffect, nextTick, reactive } from 'vue';
import { Add as AddIcon, TrashBinOutline as RemoveIcon } from '@vicons/ionicons5';
import { useVModel } from 'vue-composable';
import { FormInst, FormRules, FormValidationError, SelectOption } from 'naive-ui';
import { YourNetwork } from '@/types';
import { NetworkType } from '../../types/survey';

export default defineComponent({
  name: 'SurveyYourNetwork',
  emits: ['update:yourNetwork'],
  components: { AddIcon, RemoveIcon },
  props: {
    text: {
      type: String,
      required: true
    },
    maxEntries: {
      type: Number,
      required: true
    },
    yourNetwork: {
      type: Object as PropType<YourNetwork[]>,
      required: true
    },
    networkType: {
      type: Object as PropType<NetworkType>,
      required: true
    },
    organizationTypeList: {
      type: Object as PropType<Array<SelectOption>>,
      required: true
    }
  },
  setup(props) {
    const max = useVModel(props, 'maxEntries');
    const disableButton = ref(false);
    const inputValue = useVModel(props, 'yourNetwork');
    const nameRef = ref<HTMLInputElement[]>([]);
    const organizationRef = ref<HTMLInputElement[]>([]);
    const organizationTypeRef = ref<HTMLInputElement[]>([]);
    const organizationTypeListRef = useVModel(props, 'organizationTypeList');
    const formRef = ref<FormInst>();
    const networkType = computed(() => props.networkType);

    const focus = (index: number) => {
      if (networkType.value === NetworkType.ContactAndOrganization) {
        nameRef.value[index].focus();
      } else {
        organizationRef.value[index].focus();
      }
    };

    onMounted(() => {
      if (!inputValue.value.length) {
        inputValue.value.push({
          name: '',
          cleanName: '',
          organization: '',
          cleanOrganization: '',
          organizationType: 1,
          cleanOrganizationType: 1,
          relationshipKeys: [],
          interactionKey: 0
        });
      }
    });

    watchEffect(() => {
      if (inputValue.value.length < max.value) {
        disableButton.value = false;
      }
    });

    watch(
      () => inputValue.value.length,
      async (newLength) => {
        if (newLength > 0) {
          await nextTick();
          focus(newLength - 1);
        }
      }
    );

    const onRemove = (key: number): void => {
      inputValue.value.splice(
        inputValue.value.findIndex((_, index) => index === key),
        1
      );
    };

    const rules: ComputedRef<FormRules> = computed(() =>
      inputValue.value.reduce(
        (accumulator, _, index) => ({
          ...accumulator,
          [`nameRule-${index}`]: [
            {
              required: networkType.value === NetworkType.ContactAndOrganization, // true,
              message: 'Please enter a name.',
              validator() {
                return !!nameRef.value[index].value;
              },
              trigger: 'change'
            }
          ],
          [`organizationRule-${index}`]: [
            {
              required: true,
              message: 'Please enter an organization.',
              validator() {
                return !!organizationRef.value[index].value;
              },
              trigger: 'change'
            }
          ],
          [`organizationTypeRule-${index}`]: [
            {
              required: true,
              message: 'Please select an organization type.',
              validator() {
                return !!organizationTypeRef.value[index].value;
              },
              trigger: 'change'
            }
          ]
        }),
        {}
      )
    );

    const addInput = () => {
      if (inputValue.value.length < max.value) {
        const newItem = reactive({
          name: '',
          cleanName: '',
          organization: '',
          cleanOrganization: '',
          organizationType: 1,
          cleanOrganizationType: 1,
          relationshipKeys: [],
          interactionKey: 0
        });
        inputValue.value.push(newItem);
        nextTick(() => {
          focus(inputValue.value.length - 1);
        });
      }
      if (inputValue.value.length === max.value) {
        disableButton.value = true;
      }
    };

    const validate = () => {
      return new Promise((resolve) => {
        if (formRef.value) {
          try {
            formRef.value
              .validate((errors: Array<FormValidationError> | undefined) => {
                resolve(!errors?.length);
              })
              .catch(() => {
                resolve(false);
              });
          } catch (e) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    };

    return {
      addInput,
      disableButton,
      inputValue,
      formRef,
      nameRef,
      organizationRef,
      onRemove,
      rules,
      validate,
      organizationTypeRef,
      organizationTypeListRef
    };
  }
});
