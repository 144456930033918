import { createApp } from 'vue';
import naive from 'naive-ui';
import App from './App.vue';
import router from './router';
import { initAuth0 } from '@/auth';
import setupInterceptors from './services/axios-interceptors';
import store from './store';

const initApp = () => {
  setupInterceptors();
  const app = createApp(App);
  app.use(router);
  app.use(naive);
  app.use(store);
  app.mount('#app');
};

(async () => {
  try {
    await initAuth0({
      onRedirectCallback: (appState) => {
        router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
      },
      clientId: process.env.VUE_APP_AUTH0_CLIENT_KEY as string,
      domain: process.env.VUE_APP_AUTH0_DOMAIN as string,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
      redirectUri: window.location.origin
    });
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error('Error initializing Auth0', error);
  } finally {
    initApp();
  }
})();
