import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    path: _ctx.path,
    label: _ctx.label
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_input, {
        type: "textarea",
        autosize: {
        minRows: _ctx.minRows,
        maxRows: _ctx.maxRows
      },
        value: _ctx.inputValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        placeholder: _ctx.label
      }, null, 8, ["autosize", "value", "placeholder"])
    ]),
    _: 1
  }, 8, ["path", "label"]))
}