import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: _ctx.showModalRef,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModalRef) = $event)),
    preset: "card",
    class: "shareable-change-modal",
    "block-scroll": false,
    style: {"width":"40%"}
  }, {
    header: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.surveyRef?.shareable ? 'Make Survey Results Private?' : 'Make Survey Results Shareable?'), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_n_space, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_button, {
            type: "primary",
            onClick: _ctx.confirmShareableChange
          }, {
            default: _withCtx(() => [
              _createTextVNode("Yes")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_n_button, {
            onClick: _ctx.closeModal,
            type: "error"
          }, {
            default: _withCtx(() => [
              _createTextVNode("No")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.surveyRef?.shareable ? 'Are you sure you want to make this survey private?' : 'Are you sure you want to make this survey shareable?'), 1)
    ]),
    _: 1
  }, 8, ["show"]))
}