/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */

import { reactive, watchEffect } from 'vue';
import { NavigationGuardWithThis } from 'vue-router';
import createAuth0Client, {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User
} from '@auth0/auth0-spa-js';

let client: Auth0Client;
const authManager = reactive<Auth0Manager>({
  state: {
    loading: true,
    isAuthenticated: false,
    isAdmin: false,
    user: {},
    popupOpen: false,
    error: null
  },
  getIdTokenClaims: (o?: GetIdTokenClaimsOptions) => client.getIdTokenClaims(o),
  getTokenSilently: (o?: GetTokenSilentlyOptions) => client.getTokenSilently(o),
  getTokenWithPopup: (o?: GetTokenWithPopupOptions) => client.getTokenWithPopup(o),
  loginWithRedirect: (o?: RedirectLoginOptions) => {
    return client.loginWithRedirect(o);
  },
  logout: (o?: LogoutOptions) => client.logout(o),
  handleRedirectCallback: async () => {
    authManager.state.loading = true;

    try {
      await client.handleRedirectCallback();
      authManager.state.user = await client.getUser();
      authManager.state.isAuthenticated = true;
    } catch (e) {
      authManager.state.error = e;
    } finally {
      authManager.state.loading = false;
    }
  },
  routeGuard: (to: any, from: any, next: any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { isAuthenticated, loading } = authManager.state;
    const { loginWithRedirect } = authManager;

    const verify = async () => {
      // If the user is authenticated, continue with the route
      if (isAuthenticated) {
        return next();
      }

      // TODO: Handle the redirect. For some reason it is not going to /admin
      // Otherwise, log in
      // console.log(to.fullPath); //DEBUG
      await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!loading) {
      return verify();
    }

    // Watch for the loading property to change before we check isAuthenticated
    watchEffect(() => {
      if (!loading) {
        return verify();
      }
    });
  },
  adminGuard: async (to: any, from: any, next: any) => {
    const { isAuthenticated, loading, isAdmin } = authManager.state;
    if (!loading && isAuthenticated && isAdmin) {
      return next();
    }
    if (!loading && !isAuthenticated) {
      // User is not authenticated, login
      await authManager.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
    if (!loading && !isAdmin) {
      // User is authenticated but not an admin, redirect to home
      next('/');
    }
  }
});

export type RedirectAppState = {
  targetUrl?: string;
};

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  audience?: string;
  redirectUri?: string;

  onRedirectCallback(appState: RedirectAppState): void;
}

export type Auth0Manager = {
  state: {
    loading: boolean;
    isAuthenticated: boolean;
    isAdmin: boolean;
    user: User | undefined;
    popupOpen: boolean;
    error: any;
  };
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<void>;
  getIdTokenClaims(o?: GetIdTokenClaimsOptions): Promise<any>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<any>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<any>;
  logout(o?: LogoutOptions): void | Promise<void>;
  routeGuard: NavigationGuardWithThis<undefined>;
  adminGuard: NavigationGuardWithThis<undefined>;
};

const hasPermission = async (permission: string): Promise<boolean> => {
  let token;
  try {
    token = await authManager.getTokenSilently();
  } catch (error) {
    await authManager.loginWithRedirect();
  }
  const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;
  if (decodedToken) return decodedToken?.permissions?.includes(permission);
  return false;
};

export const initAuth0 = async (o: Auth0PluginOptions): Promise<void> => {
  client = await createAuth0Client({
    domain: o.domain,
    client_id: o.clientId,
    audience: o.audience,
    redirect_uri: o.redirectUri,
    useRefreshTokens: true,
    cacheLocation: 'localstorage'
  });

  try {
    authManager.state.isAuthenticated = await client.isAuthenticated();
    // If the user is returning to the app after authentication
    if (window.location.search.includes('code=') && window.location.search.includes('state=') && !authManager.state.isAuthenticated) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();
      // console.log('appState', appState); //DEBUG

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      o.onRedirectCallback(appState);
    }
  } catch (e: any) {
    authManager.state.error = e;
    if (e.error === 'login_required') {
      // Force a login if needed
      await authManager.loginWithRedirect();
    }
  } finally {
    // Initialize our internal authentication state
    authManager.state.user = await client.getUser();
    if (authManager.state.isAuthenticated) authManager.state.isAdmin = await hasPermission('admin');
    authManager.state.loading = false;
  }
};

export const useAuth0 = (): Auth0Manager => authManager;
