import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createBlock(_component_n_space, {
    style: {"flex-flow":"nowrap","justify-content":"end"},
    class: "user-buttons"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_button, {
        type: "default",
        onClick: _ctx.editUser
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_n_icon, { size: "20" }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Edit ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_n_button, {
        tertiary: "",
        class: "delete-button",
        type: "error",
        onClick: _ctx.deleteUser
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_n_icon, { size: "20" }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Delete ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}