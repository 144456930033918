
import { defineComponent } from 'vue';
import DataCleanupComponent from '../../components/data-cleanup/DataCleanupComponent.vue';

export default defineComponent({
  name: 'DataCleanup',
  components: { DataCleanupComponent },
  props: {
    surveyId: {
      type: String,
      required: true
    }
  },
  setup() {
    return {};
  }
});
