
import { defineComponent, reactive, toRefs, ref, onBeforeMount, computed } from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMessage, StepsProps, SelectOption } from 'naive-ui';
import { DateTime } from 'luxon';
import mergeWith from 'lodash.mergewith';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PageState, Survey, Response, NetworkType } from '@/types';
import { mapToSelectOption } from '@/utils/map-to-select-option';
import surveyService from '@/services/survey-service';
import LoadingBar from '@/objects/loading-bar';
import SurveyMainPage from '@/components/survey/SurveyMainPage.vue';
import SurveyYourInfo from '@/components/survey/SurveyYourInfo.vue';
import SurveyYourNetwork from '@/components/survey/SurveyYourNetwork.vue';
import SurveyRelationships from '@/components/survey/SurveyRelationships.vue';
import SurveyInteractions from '@/components/survey/SurveyInteractions.vue';
import SurveyThankYou from '@/components/survey/SurveyThankYou.vue';
import SurveyButtons from '@/components/SurveyButtons.vue';
import responseService from '@/services/response-service';

export default defineComponent({
  name: 'SurveyComponent',
  components: {
    SurveyMainPage,
    SurveyYourInfo,
    SurveyYourNetwork,
    SurveyRelationships,
    SurveyInteractions,
    SurveyThankYou,
    SurveyButtons
  },

  props: {
    takeGuid: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const validInput = ref(false);
    const currentRef = ref<number>(0);
    const message = useMessage();
    const loadingBar = new LoadingBar();
    const yourInfo = ref<InstanceType<typeof SurveyYourInfo>>();
    const yourNetwork = ref<InstanceType<typeof SurveyYourNetwork>>();
    const relationships = ref<InstanceType<typeof SurveyRelationships>>();
    const interactions = ref<InstanceType<typeof SurveyInteractions>>();
    const completedSurvey = ref(false);
    const userHasAgreed = ref(false);
    loadingBar.start();

    const SURVEY_TABS = {
      MAIN_PAGE: 0,
      YOUR_INFO: 1,
      YOUR_NETWORK: 2,
      RELATIONSHIPS: 3,
      INTERACTIONS: 4
    };

    const responseEditingState = ref<Response>({
      id: '',
      surveyId: '',
      email: '',
      createdOn: '',
      updatedOn: null,
      createdBy: '',
      sentOn: '',
      viewedOn: '',
      completedOn: null,
      info: {
        name: '',
        cleanName: '',
        email: '',
        role: '',
        organization: '',
        cleanOrganization: '',
        organizationType: 1,
        cleanOrganizationType: 1,
        zipCode: '',
        participationInNetworkType: 1
      },
      network: {
        yourNetwork: []
      }
    });

    const state = reactive<{
      surveyState: PageState<Survey | undefined>;
      responseState: PageState<Response | undefined>;
    }>({
      surveyState: {
        loaded: false,
        data: undefined
      },
      responseState: {
        loaded: false,
        data: undefined
      }
    });

    const loadSurvey = (data: Survey) => {
      state.surveyState.data = data;
      state.surveyState.loaded = true;
      loadingBar.finish();
    };

    const loadResponse = (data: Response) => {
      responseEditingState.value = mergeWith({}, responseEditingState.value, data, (a: unknown, b: unknown) => b ?? a);
      responseEditingState.value.info.email = responseEditingState.value.email;
      state.responseState.loaded = true;
      loadingBar.finish();
    };

    onBeforeMount(() => {
      surveyService
        .getSurveyToTake(props.takeGuid)
        .then((survey) => {
          if (!survey) {
            message.error(`Failed to load survey`);
            return;
          }
          loadSurvey(survey);
          responseService.get(props.userId).then((response) => {
            if (response) {
              if (!response.viewedOn) {
                response.viewedOn = DateTime.now().toISO();
                responseService.update(response);
              }
              if (response.completedOn !== null) {
                completedSurvey.value = true;
              }
              loadResponse(response);
            }
          });
        })
        .catch(() => {
          loadingBar.error();
          message.error(`Failed to load survey`);
        });

      responseService.get(props.userId);

      loadingBar.finish();
    });

    const mappedOrganizationTypeList = computed(() => mapToSelectOption(state.surveyState.data?.organizationTypeList, 'key', 'label'));
    const mappedParticipationNetworkTypeList = computed(() => mapToSelectOption(state.surveyState.data?.participationNetworkTypeList, 'key', 'label'));
    const computedParticipationNetworkQuestion = computed(() => state.surveyState.data?.participationNetworkQuestion);
    const updateResponse = async (validate: Promise<unknown> | boolean | undefined, data: Response) => {
      if (await validate) {
        if (currentRef.value === SURVEY_TABS.INTERACTIONS) {
          // eslint-disable-next-line no-param-reassign
          data.completedOn = DateTime.now().toISO();
        }
        try {
          await responseService
            .update(data)
            .then(() => {
              loadingBar.finish();
              currentRef.value += 1;
            })
            .catch(() => {
              loadingBar.error();
            });
        } catch (e) {
          message.error(`An error occurred.`);
        }
      }
    };

    return {
      ...toRefs(state),
      responseEditingState,
      mappedOrganizationTypeList,
      mappedParticipationNetworkTypeList,
      currentStatus: ref<StepsProps['status']>('process'),
      current: currentRef,
      showPrev() {
        currentRef.value -= 1;
      },
      showNext: async () => {
        state.responseState.data = responseEditingState.value;
        loadingBar.finish();
        if (currentRef.value === SURVEY_TABS.YOUR_INFO) {
          responseEditingState.value.info.cleanName = responseEditingState.value.info.name;
          responseEditingState.value.info.cleanOrganization = responseEditingState.value.info.organization;
          responseEditingState.value.info.cleanOrganizationType = responseEditingState.value.info.organizationType;

          await updateResponse(yourInfo.value?.validate(), state.responseState.data);
        } else if (currentRef.value === SURVEY_TABS.YOUR_NETWORK) {
          responseEditingState.value.network.yourNetwork.forEach((network) => {
            network.cleanName = network.name;
            network.cleanOrganization = network.organization;
            network.cleanOrganizationType = network.organizationType;
          });

          await updateResponse(yourNetwork.value?.validate(), state.responseState.data);
        } else if (currentRef.value === SURVEY_TABS.RELATIONSHIPS) {
          await updateResponse(relationships.value?.validate(), state.responseState.data);
        } else {
          currentRef.value += 1;
        }
      },
      submitSurvey: async () => {
        state.responseState.data = responseEditingState.value;
        loadingBar.finish();
        updateResponse(interactions.value?.validate(), state.responseState.data);
      },
      validInput,
      yourInfo,
      yourNetwork,
      relationships,
      interactions,
      completedSurvey,
      SURVEY_TABS,
      computedParticipationNetworkQuestion,
      userHasAgreed
    };
  }
});
