import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "survey-buttons"
}
const _hoisted_2 = {
  key: 0,
  class: "survey-back"
}
const _hoisted_3 = {
  key: 1,
  class: "survey-continue"
}
const _hoisted_4 = {
  key: 2,
  class: "survey-continue"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_left = _resolveComponent("chevron-left")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_chevron_right = _resolveComponent("chevron-right")!

  return (_ctx.backButtonRef || _ctx.continueButtonRef)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.backButtonRef)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_n_button, {
                onClick: _ctx.handlePreviousClick,
                type: "primary",
                class: "back-button"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_n_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_chevron_left, { class: "back-button-icon" })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Back ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.continueButtonRef)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_n_button, {
                onClick: _ctx.handleContinueClick,
                type: "primary",
                class: "continue-button"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_n_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_chevron_right, { class: "continue-button-icon" })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Continue ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.finishButtonRef)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_n_button, {
                onClick: _ctx.handleFinishClick,
                type: "primary",
                class: "continue-button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Submit ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}