
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { AddSharp as CreateIcon, PencilOutline as EditIcon, TrashBinOutline as DeleteIcon } from '@vicons/ionicons5';
import { useMessage } from 'naive-ui';
import LoadingBar from '@/objects/loading-bar';
import { PageState, Region, User } from '@/types';
import regionService from '@/services/region-service';
import RegionEditModal from '@/components/region/RegionEditModal.vue';
import ConfirmRegionDeleteModal from '@/components/region/ConfirmRegionDeleteModal.vue';
import CannotDeleteRegionModal from '../region/CannotDeleteRegionModal.vue';
import userService from '@/services/user-service';
// eslint-disable @typescript-eslint/no-non-null-assertion
export default defineComponent({
  name: 'RegionAdminList',
  components: { CreateIcon, EditIcon, DeleteIcon, RegionEditModal, ConfirmRegionDeleteModal, CannotDeleteRegionModal },

  setup() {
    const state = reactive<
      PageState<Region[]> & {
        regionName: string;
        regionId: string;
        regionManagers: User[];
        showEditModal: boolean;
        showDeleteModal: boolean;
        showCannotDeleteModal: boolean;
        selectedRegion: Region | null;
      }
    >({
      loaded: false,
      data: [] as Region[],
      selectedRegion: null,
      showEditModal: false,
      showDeleteModal: false,
      showCannotDeleteModal: false,
      regionId: '',
      regionName: '',
      regionManagers: []
    });
    const loadingBar = new LoadingBar();
    const message = useMessage();
    const loadRegionalManagers = () => {
      userService.getManagers().then((managers) => {
        state.regionManagers = managers;
      });
    };
    const fetchRegions = () => {
      loadingBar.start();
      regionService
        .getList()
        .then((list) => {
          state.data = [...list];
          loadingBar.finish();
          state.loaded = true;
        })
        .catch(() => {
          loadingBar.error();
          message.error('Failed to load regions');
        });
      loadRegionalManagers();
    };

    onMounted(() => {
      fetchRegions();
    });

    return {
      ...toRefs(state),
      createRegion() {
        regionService.getDefault().then((region) => {
          state.selectedRegion = region;
          state.showEditModal = true;
        });
      },
      editRegion(id: string) {
        const region = state.data.find((r) => r.id === id);
        if (region) {
          state.selectedRegion = region;
          state.showEditModal = true;
        }
      },
      deleteRegion(id: string) {
        const region = state.data.find((r) => r.id === id);
        if (region) {
          state.selectedRegion = region;
        }
        if (region!.managerIds.length > 0 || region!.surveyCount > 0) {
          state.showCannotDeleteModal = true;
        } else state.showDeleteModal = true;
      },
      fetchRegions
    };
  }
});
