import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "survey-your-info" }
const _hoisted_2 = { class: "survey-your-info__org-zip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h1 = _resolveComponent("n-h1")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_form = _resolveComponent("n-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_h1, null, {
      default: _withCtx(() => [
        _createTextVNode("Your Info")
      ]),
      _: 1
    }),
    _createVNode(_component_n_form, {
      ref: "formRef",
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_n_form_item, {
          path: "nameRule",
          label: "Name: (Full First and Last Name)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_input, {
              value: _ctx.nameRef,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nameRef) = $event)),
              placeholder: "",
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_n_form_item, {
          path: "emailRule",
          label: "Email:"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_input, {
              value: _ctx.emailRef,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailRef) = $event)),
              type: "email",
              placeholder: "example@email.com",
              onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_n_form_item, {
          path: "roleRule",
          label: "Role/Position:"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_input, {
              value: _ctx.roleRef,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.roleRef) = $event)),
              type: "text",
              placeholder: "",
              onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_n_form_item, {
          path: "organizationRule",
          label: "Organization:"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_input, {
              value: _ctx.organizationRef,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.organizationRef) = $event)),
              type: "text",
              placeholder: "",
              onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_n_form_item, {
            span: 12,
            path: "organizationTypeRule",
            label: "Organization Type:"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_select, {
                value: _ctx.organizationTypeValue,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.organizationTypeValue) = $event)),
                placeholder: "Please choose...",
                options: _ctx.organizationTypeList,
                onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_n_form_item, {
            path: "zipCodeRule",
            label: "Zip Code:"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_input, {
                value: _ctx.zipCodeRef,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.zipCodeRef) = $event)),
                type: "text",
                placeholder: "",
                onKeydown: _cache[11] || (_cache[11] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_n_form_item, {
          span: 12,
          path: "participationInNetworkRule",
          label: _ctx.participationInNetworkQuestionRef.length > 0 ? _ctx.participationInNetworkQuestionRef.concat(':') : 'Participation In Network:'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_select, {
              value: _ctx.participationInNetworkValue,
              "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.participationInNetworkValue) = $event)),
              placeholder: "Please choose...",
              options: _ctx.participationInNetworkList,
              onKeydown: _cache[13] || (_cache[13] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
            }, null, 8, ["value", "options"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["rules"])
  ]))
}