import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c9bae2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remove" }
const _hoisted_2 = { class: "add" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_remove_icon = _resolveComponent("remove-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_create_icon = _resolveComponent("create-icon")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    path: _ctx.path,
    label: _ctx.label
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_n_text, { italic: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Weight and Option Name")
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedList, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            key: option.key,
            style: {"display":"flex","align-items":"center"}
          }, [
            _createVNode(_component_n_select, {
              placeholder: "Weight",
              options: _ctx.weightOptions,
              value: option.weight,
              "onUpdate:value": ($event: any) => ((option.weight) = $event),
              style: {"width":"460px","margin-right":"0.2rem"}
            }, null, 8, ["options", "value", "onUpdate:value"]),
            _createVNode(_component_n_input, {
              value: option.label,
              "onUpdate:value": ($event: any) => ((option.label) = $event),
              type: "text",
              placeholder: "Option Name",
              style: {"width":"460px"}
            }, null, 8, ["value", "onUpdate:value"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_n_button, {
                circle: "",
                onClick: ($event: any) => (_ctx.onRemove(option.key)),
                title: "remove option"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_n_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_remove_icon)
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_n_button, {
            circle: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCreate())),
            title: "add option"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_n_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_create_icon)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["path", "label"]))
}