import { ResponseDataColumn } from '@/types/response-data-column';
import { Response } from '@/types';
import { createIdentifierFromStrings } from './identifierUtils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapDataToResponseColumns = (responses: Response[]): ResponseDataColumn[] => {
  const dataColumns = [] as ResponseDataColumn[];
  responses.forEach((response) => {
    dataColumns.push({
      id: `respondent_${response.id}`,
      responseId: response.id,
      isInYourNetwork: false,
      organization: response.info.organization,
      cleanOrganization: response.info.cleanOrganization,
      name: response.info.name,
      cleanName: response.info.cleanName,
      organizationType: response.info.organizationType,
      cleanOrganizationType: response.info.cleanOrganizationType,
      submittedOn: response.completedOn,
      updatedOn: '',
      hasChanged: false,
      personMatches: 0,
      personMatchList: [],
      organizationMatches: 0,
      personIdentifier: createIdentifierFromStrings([
        response.info.cleanName as string,
        response.info.cleanOrganization as string,
        response.info.cleanOrganizationType?.toString() as string
      ]),
      organizationIdentifier: createIdentifierFromStrings([response.info.cleanOrganization as string, response.info.cleanOrganizationType?.toString() as string])
    });
    response.network.yourNetwork.forEach((network, index) => {
      dataColumns.push({
        id: `network_${response.id}_${index}`,
        responseId: response.id,
        isInYourNetwork: true,
        organization: network.organization,
        cleanOrganization: network.cleanOrganization,
        name: network.name,
        cleanName: network.cleanName,
        organizationType: network.organizationType,
        cleanOrganizationType: network.cleanOrganizationType,
        submittedOn: response.completedOn,
        updatedOn: '',
        hasChanged: false,
        personMatches: 0,
        personMatchList: [],
        organizationMatches: 0,
        personIdentifier: createIdentifierFromStrings([network.cleanName as string, network.cleanOrganization as string, network.cleanOrganizationType?.toString() as string]),
        organizationIdentifier: createIdentifierFromStrings([network.cleanOrganization as string, network.cleanOrganizationType?.toString() as string])
      });
    });
  });

  return dataColumns;
};
