/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import { Ref } from 'vue';
import * as d3 from 'd3';
import { Graph, NetworkType, Node } from '@/types';

export class GraphService {
  private graph: Ref<Graph>;

  private svgRef: Ref<SVGSVGElement | undefined>;

  private graphRef: Ref<HTMLElement | undefined>;

  private networkTypeRef: Ref<NetworkType>;

  private zoomConfig = {
    minScale: 0.1,
    maxScale: 4
  };

  constructor(graph: Ref<Graph>, svgRef: Ref<SVGSVGElement | undefined>, graphRef: Ref<HTMLElement | undefined>, networkTypeRef: Ref<NetworkType>) {
    this.graph = graph;
    this.svgRef = svgRef;
    this.graphRef = graphRef;
    this.networkTypeRef = networkTypeRef;
  }

  public renderGraph(): void {
    if (!this.svgRef.value || !this.graphRef.value) return;

    const clonedGraph = JSON.parse(JSON.stringify(this.graph.value));

    const { width, height } = this.calculateDimensions();

    this.initializeSVG(width, height);
    const svg = d3.select(this.svgRef.value);
    const container = svg.append('g');

    this.renderLinksAndNodes(container, clonedGraph, width, height);

    this.setupZoom(container, svg);
  }

  private calculateDimensions(): { width: number; height: number } {
    if (!this.graphRef.value) throw new Error('Graph container is not defined');
    return {
      width: this.graphRef.value.offsetWidth,
      height: this.graphRef.value.offsetHeight
    };
  }

  private initializeSVG(width: number, height: number): void {
    if (!this.svgRef.value) throw new Error('SVG Element is not found');

    d3.select(this.svgRef.value).selectAll('*').remove();
    d3.select(this.svgRef.value).attr('width', width).attr('height', height);
  }

  private setupZoom(container: d3.Selection<SVGGElement, unknown, null, undefined>, svg: d3.Selection<SVGSVGElement, unknown, null, undefined>): void {
    const zoom = d3
      .zoom()
      .scaleExtent([this.zoomConfig.minScale, this.zoomConfig.maxScale])
      .on('zoom', (event) => container.attr('transform', event.transform));

    (svg as unknown as d3.Selection<Element, unknown, null, undefined>).call(zoom);
  }

  private renderLinksAndNodes(container: d3.Selection<SVGGElement, unknown, null, undefined>, graph: Graph, width: number, height: number): void {
    const simulation = d3
      .forceSimulation(graph.nodes)
      .force(
        'link',
        d3
          .forceLink(graph.links)
          .id((d: d3.SimulationNodeDatum) => (d as Node).id)
          .strength(0.3)
          .distance(200)
      )

      .force('charge', d3.forceManyBody().strength(-600))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collide', d3.forceCollide().radius(30));

    const links = container.append('g').selectAll('line').data(graph.links).enter().append('line').attr('stroke', 'gray').attr('stroke-width', 1.5);

    const nodeGroups = container.append('g').attr('class', 'nodes').selectAll('g.node').data(graph.nodes).enter().append('g').attr('class', 'node');

    nodeGroups
      .append('circle')
      .attr('r', 7)
      .attr('fill', (d: Node) => (d.color && d.color != '' ? d.color : 'black'));

    nodeGroups.each(function (d) {
      const group = d3.select(this);

      const labelElement = group.append('text').attr('class', 'node-label').attr('x', 8).attr('y', '-0.5em').text(d.name);

      const labelBBox = labelElement.node()?.getBBox();

      group
        .insert('rect', 'text')
        .attr('x', labelBBox?.x ?? 0)
        .attr('y', labelBBox?.y ?? 0)
        .attr('width', labelBBox?.width ?? 0)
        .attr('height', labelBBox?.height ?? 0)
        .attr('fill', 'white');

      if (d.detail && Array.isArray(d.detail)) {
        d.detail.forEach((detail, index) => {
          const detailElement = group
            .append('text')
            .attr('class', 'node-detail')
            .attr('x', 8)
            .attr('y', `${(index + 1) * 1.2}em`)
            .text(detail);

          const detailBBox = detailElement.node()?.getBBox();

          group
            .insert('rect', 'text')
            .attr('x', detailBBox?.x ?? 0)
            .attr('y', detailBBox?.y ?? 0)
            .attr('width', detailBBox?.width ?? 0)
            .attr('height', detailBBox?.height ?? 0)
            .attr('fill', 'white');
        });
      }
    });
    simulation.on('tick', () => {
      links
        .attr('x1', (d) => (d.source as Node).x ?? 0)
        .attr('y1', (d) => (d.source as Node).y ?? 0)
        .attr('x2', (d) => (d.target as Node).x ?? 0)
        .attr('y2', (d) => (d.target as Node).y ?? 0);
      nodeGroups.attr('transform', (d) => `translate(${d.x ?? 0},${d.y ?? 0})`);
    });

    this.setupDrag(simulation, nodeGroups);
  }

  private setupDrag(simulation: d3.Simulation<Node, undefined>, nodeGroups: d3.Selection<SVGGElement, Node, SVGGElement, unknown>): void {
    const dragHandler = d3
      .drag<SVGGElement, Node>()
      .on('start', (event, d) => {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      })
      .on('drag', (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
      })
      .on('end', (event, d) => {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      });

    nodeGroups.call(dragHandler);
  }
}
