
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useMessage, useDialog, TabsInst } from 'naive-ui';
import { Survey } from '@/types';
import TextArea from '@/components/inputs/TextArea.vue';
import WeightedOptionsEditor from '@/components/inputs/WeightedOptionsEditor.vue';
import LoadingBar from '@/objects/loading-bar';
import surveyService from '@/services/survey-service';
import regionService from '@/services/region-service';
import OptionsEditor from '@/components/inputs/OptionsEditor.vue';
import TwoColumnOptionsEditor from '@/components/inputs/TwoColumnOptionsEditor.vue';

type RegionOption = {
  value: string;
  label: string;
};

export default defineComponent({
  name: 'SurveyEdit',
  components: { TextArea, WeightedOptionsEditor, OptionsEditor, TwoColumnOptionsEditor },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const regionOptions = ref<RegionOption[]>([]);
    const router = useRouter();
    const message = useMessage();
    const dialog = useDialog();
    const loadingBar = new LoadingBar();
    const currentTab = ref<number>(0);
    const tabsInstRef = ref<TabsInst | null>(null);
    const CREATE_SURVEY_TABS = ['details', 'questions', 'email'];
    const valueRef = ref(CREATE_SURVEY_TABS[0]);
    const isValidRegion = ref<boolean>(true);

    loadingBar.start();

    const state = reactive({
      loaded: false,
      data: null as Survey | null,
      selectedRegion: null as string | null
    });

    const validateRegion = () => {
      isValidRegion.value = !!state.selectedRegion;
    };

    const loadSurvey = (data: Survey) => {
      state.data = data;
      if (data.region) {
        state.selectedRegion = data.region.id;
      } else {
        state.selectedRegion = state.selectedRegion ? state.selectedRegion : null;
      }
      state.loaded = true;
      loadingBar.finish();
    };

    const loadRegionOptions = async () => {
      try {
        const userRegions = await regionService.getUserRegions();
        regionOptions.value = userRegions.map((region) => ({ value: region.id, label: region.name }));
        if (regionOptions.value.length === 1) {
          state.selectedRegion = regionOptions.value[0].value;
        }
      } catch (error) {
        message.error('Failed to load regions');
      }
    };

    const warningMessage = computed(() => {
      let text = 'This survey has been ';
      const conditions = [];

      if ((state.data?.completedCount ?? 0) > 0) {
        conditions.push(`completed ${state.data?.completedCount} time${(state.data?.completedCount ?? 0) > 1 ? 's' : ''}`);
      }
      if ((state.data?.sentCount ?? 0) > 0) {
        conditions.push(`sent ${state.data?.sentCount} time${(state.data?.sentCount ?? 0) > 1 ? 's' : ''}`);
      }
      if ((state.data?.viewCount ?? 0) > 0) {
        conditions.push(`viewed ${state.data?.viewCount} time${(state.data?.viewCount ?? 0) > 1 ? 's' : ''}`);
      }

      if (conditions.length === 0) return '';

      if (conditions.length > 1) {
        text += `${conditions.slice(0, -1).join(', ')}, and ${conditions.slice(-1)}`;
      } else {
        text += conditions[0];
      }
      text += '. This survey should not be edited unless absolutely necessary.';
      return text;
    });

    onMounted(async () => {
      if (props.id === 'new') {
        surveyService
          .getDefault()
          .then((survey) => loadSurvey(survey))
          .catch((error) => {
            loadingBar.error();
            // eslint-disable-next-line no-console
            console.error(error);
            message.error(`Failed to load survey ${props.id}`);
          });
      } else {
        surveyService
          .get(props.id)
          .then((survey) => loadSurvey(survey))
          .catch(() => {
            loadingBar.error();
            message.error(`Failed to load survey ${props.id}`);
          });
      }
      await loadRegionOptions();
    });

    const cancel = () => {
      dialog.warning({
        title: 'Confirm',
        content: 'Are you sure want to cancel?',
        positiveText: 'Yes, cancel',
        negativeText: 'No, keep editing',
        onPositiveClick: () => {
          router.push({ path: '/admin' });
        }
      });
    };

    const showPrev = () => {
      if (currentTab.value !== CREATE_SURVEY_TABS.indexOf(valueRef.value)) {
        currentTab.value = CREATE_SURVEY_TABS.indexOf(valueRef.value);
      }
      currentTab.value -= 1;
      valueRef.value = CREATE_SURVEY_TABS[currentTab.value];
      nextTick(() => tabsInstRef.value?.syncBarPosition());
    };

    const showNext = () => {
      validateRegion();
      if (!isValidRegion.value) {
        message.error('Please select a region');
        return;
      }
      if (currentTab.value !== CREATE_SURVEY_TABS.indexOf(valueRef.value)) {
        currentTab.value = CREATE_SURVEY_TABS.indexOf(valueRef.value);
      }
      currentTab.value += 1;
      valueRef.value = CREATE_SURVEY_TABS[currentTab.value];
      nextTick(() => tabsInstRef.value?.syncBarPosition());
    };

    const save = () => {
      loadingBar.finish();
      validateRegion();
      if (!isValidRegion.value) {
        message.error('Please select a region');
        return;
      }
      if (state.data) {
        const region = regionOptions.value.find((r) => r.value === state.selectedRegion);
        state.data.region = {
          id: state.selectedRegion ?? '',
          name: region?.label ?? 'Unknown'
        };
      }
      if (state.data?.id) {
        surveyService
          .update(state.data)
          .then(() => {
            message.success('Survey Updated');
            loadingBar.finish();
          })
          .catch(() => {
            loadingBar.error();
            message.error(`Failed to update survey ${props.id}`);
          });
      } else if (state.data) {
        surveyService
          .create(state.data)
          .then(() => {
            message.success('Survey Created');
            loadingBar.finish();
          })
          .catch(() => {
            loadingBar.error();
            message.error(`Failed to create survey`);
          })
          .finally(() => {
            router.push({ path: '/admin' });
          });
      }
    };

    // TODO: grab this list from a service
    const networkTypeOptions = [
      { value: 1, label: 'Organization Only' },
      { value: 2, label: 'Organization And Person' }
    ];
    // array from 1 to 100
    const maxEntriesOptions = Array.from(Array(100).keys()).map((i) => ({
      value: i + 1,
      label: i + 1
    }));

    return {
      ...toRefs(state),
      networkTypeOptions,
      maxEntriesOptions,
      currentTab,
      CREATE_SURVEY_TABS,
      valueRef,
      cancel,
      showPrev,
      showNext,
      save,
      regionOptions,
      isValidRegion,
      validateRegion,
      warningMessage
    };
  }
});
