
import { defineComponent } from 'vue';
import { WarningOutline as ErrorIcon } from '@vicons/ionicons5';

export default defineComponent({
  name: 'SurveyNotFound',
  components: {
    ErrorIcon
  },
  setup() {
    return {};
  }
});
