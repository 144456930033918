
import { defineComponent } from 'vue';
import { useVModel } from 'vue-composable';

export default defineComponent({
  name: 'TextArea',
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    minRows: {
      type: Number,
      required: false,
      default: 3
    },
    maxRows: {
      type: Number,
      required: false
    }
  },
  setup(props) {
    const inputValue = useVModel(props, 'modelValue');

    return {
      inputValue
    };
  }
});
