import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wide-content admin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataCleanupComponent = _resolveComponent("DataCleanupComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataCleanupComponent, { surveyId: _ctx.surveyId }, null, 8, ["surveyId"])
  ]))
}