import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb6da52e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graph-container" }
const _hoisted_2 = {
  ref: "graphRef",
  class: "graph"
}
const _hoisted_3 = { ref: "svgRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialGraphSideBar = _resolveComponent("SocialGraphSideBar")!
  const _component_SocialGraphLegend = _resolveComponent("SocialGraphLegend")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SocialGraphSideBar, {
      isPublic: _ctx.isPublicRef,
      survey: _ctx.surveyRef,
      onViewOptionChange: _ctx.setViewOption
    }, null, 8, ["isPublic", "survey", "onViewOptionChange"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock("svg", _hoisted_3, null, 512)),
        _createVNode(_component_SocialGraphLegend, {
          colorList: _ctx.surveyRef?.nodeColors ?? [],
          nodeList: _ctx.surveyRef?.organizationTypeList ?? []
        }, null, 8, ["colorList", "nodeList"])
      ], 512)
    ])
  ], 64))
}