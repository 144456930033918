import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8680d314"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "summary-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "summary-detail primary"
}
const _hoisted_3 = { class: "summary-detail" }
const _hoisted_4 = { class: "summary-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchIndicator = _resolveComponent("MatchIndicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (column) => {
      return (_openBlock(), _createElementBlock("div", {
        key: column.id,
        class: "summary-item"
      }, [
        (_ctx.summaryType === 'person')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(column.cleanName), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.organizationFieldClass)
        }, [
          _createElementVNode("p", null, _toDisplayString(column.cleanOrganization), 1)
        ], 2),
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.organizationTypeList.find((orgType) => orgType.key === column.cleanOrganizationType)?.label), 1)
        ]),
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_MatchIndicator, {
            matchNumber: _ctx.summaryType === 'organization' ? column.organizationMatches : column.personMatches,
            matchType: _ctx.summaryType
          }, null, 8, ["matchNumber", "matchType"])
        ])
      ]))
    }), 128))
  ]))
}