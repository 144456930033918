import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_reset_icon = _resolveComponent("reset-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_tooltip = _resolveComponent("n-tooltip")!
  const _component_n_input_group = _resolveComponent("n-input-group")!
  const _component_ConfirmTextFieldResetModal = _resolveComponent("ConfirmTextFieldResetModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_input_group, null, {
      default: _withCtx(() => [
        (_ctx.showTextInput)
          ? (_openBlock(), _createBlock(_component_n_input, {
              key: 0,
              value: _ctx.valueRef,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueRef) = $event)),
              placeholder: _ctx.fieldName,
              type: "text",
              "on-update:value": _ctx.onUpdateValueFunction
            }, null, 8, ["value", "placeholder", "on-update:value"]))
          : _createCommentVNode("", true),
        (_ctx.showSelectInput)
          ? (_openBlock(), _createBlock(_component_n_select, {
              key: 1,
              value: _ctx.valueRef,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueRef) = $event)),
              options: _ctx.selectedOptionsRef,
              placeholder: _ctx.fieldName,
              "on-update:value": _ctx.onUpdateValueFunction
            }, null, 8, ["value", "options", "placeholder", "on-update:value"]))
          : _createCommentVNode("", true),
        _createVNode(_component_n_tooltip, null, {
          trigger: _withCtx(() => [
            _createVNode(_component_n_button, {
              type: "primary",
              onClick: _ctx.handleReset,
              class: _normalizeClass(_ctx.buttonStyle)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_icon, { size: "20" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_reset_icon)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick", "class"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, [
              _createTextVNode(" Reset to initial value: "),
              _createElementVNode("strong", null, _toDisplayString(_ctx.resetValueLabel), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmTextFieldResetModal, {
      onConfirmReset: _ctx.resetField,
      showModal: _ctx.showConfirmResetModal,
      "onUpdate:showModal": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showConfirmResetModal) = $event)),
      fieldValue: _ctx.fieldValueLabel,
      resetValue: _ctx.resetValueLabel,
      fieldName: _ctx.fieldName
    }, null, 8, ["onConfirmReset", "showModal", "fieldValue", "resetValue", "fieldName"])
  ], 64))
}