
import { computed, defineComponent, h, onMounted, reactive, toRefs } from 'vue';
import { AddSharp as CreateIcon, SearchOutline as SearchIcon } from '@vicons/ionicons5';
import UserActions from '../users/UserActions.vue';
import { User } from '@/types';
import userService from '@/services/user-service';
import regionService from '@/services/region-service';
import LoadingBar from '@/objects/loading-bar';
import UserEditModal from '../users/UserEditModal.vue';
import ConfirmUserDeleteModal from '../users/ConfirmUserDeleteModal.vue';

export default defineComponent({
  name: 'UserAdminList',
  components: { CreateIcon, UserEditModal, ConfirmUserDeleteModal, SearchIcon },
  setup() {
    const state = reactive({
      usersLoaded: false,
      regionsLoaded: false,
      filteredData: [] as User[],
      originalData: [] as User[],
      regionOptions: [] as { value: string; label: string }[],
      selectedRegionIds: [] as string[],
      showUserEditModal: false,
      showConfirmDeleteModal: false,
      selectedUser: null as User | null,
      searchQuery: ''
    });
    const pagination = {
      pageSize: 12
    };
    const editUser = (user: User) => {
      state.selectedUser = user;
      state.showUserEditModal = true;
    };
    const deleteUser = (user: User) => {
      state.selectedUser = user;
      state.showConfirmDeleteModal = true;
    };
    const columns = [
      {
        title: 'User',
        key: 'email'
      },
      {
        title: 'Role',
        key: 'role'
      },
      {
        title: 'Regions',
        key: 'regions',
        render: (row: User) => row.regions.map((r) => r.name).join(', ')
      },
      {
        title: '',
        key: 'actions',
        render: (row: User) =>
          h(UserActions, {
            user: row,
            onEdit: (user) => editUser(user),
            onDelete: (user) => deleteUser(user)
          })
      }
    ];
    const roles = [
      { value: 'Admin', label: 'Admin' },
      { value: 'Regional Manager', label: 'Regional Manager' }
    ];
    const loadingBar = new LoadingBar();
    const loadUsers = async () => {
      loadingBar.start();
      userService.getAll().then((list) => {
        state.originalData = list;
        state.filteredData = list;
        state.usersLoaded = true;
        loadingBar.finish();
      });
    };
    const removeUserFromList = (userId: string) => {
      state.originalData = state.originalData.filter((user) => user.id !== userId);
      state.filteredData = state.filteredData.filter((user) => user.id !== userId);
    };
    const addUserToList = (user: User) => {
      const updatedRegions = user.regions.map((region) => {
        return { id: region.id, name: state.regionOptions.find((option) => option.value === region.id)?.label || 'Unknown' };
      });

      const userWithRegionNames = { ...user, regions: updatedRegions };

      state.originalData.push(userWithRegionNames);
    };
    const updateUserInList = (user: User) => {
      const index = state.originalData.findIndex((u) => u.id === user.id);
      if (index !== -1) {
        state.originalData[index] = user;
      }
    };
    const addUser = async () => {
      const user: User = {
        id: 'new',
        name: '',
        email: '',
        role: 'Regional Manager',
        regions: []
      };
      state.selectedUser = user;
      state.showUserEditModal = true;
    };
    const loadRegions = async () => {
      regionService.getList().then((list) => {
        state.regionOptions = list.map((r) => ({ value: r.id, label: r.name }));
        state.regionsLoaded = true;
      });
    };

    const filteredData = computed(() => {
      const searchQuery = state.searchQuery.toLowerCase();
      return state.originalData.filter((user) => {
        return (
          user.email.toLowerCase().includes(searchQuery) ||
          user.role.toLowerCase().includes(searchQuery) ||
          user.regions
            .map((r) => r.name)
            .join(', ')
            .toLowerCase()
            .includes(searchQuery)
        );
      });
    });

    onMounted(async () => {
      await loadRegions();
      await loadUsers();
    });
    loadingBar.finish();
    return { ...toRefs(state), columns, filteredData, roles, addUser, loadUsers, pagination, removeUserFromList, addUserToList, updateUserInList };
  }
});
