
import { computed, defineComponent, PropType } from 'vue';
import { useVModel } from 'vue-composable';
import { useDialog } from 'naive-ui';
import { AddSharp as CreateIcon, RemoveOutline as RemoveIcon } from '@vicons/ionicons5';
import { WeightedOption } from '@/types';

export default defineComponent({
  name: 'WeightedOptionsEditor',
  components: { CreateIcon, RemoveIcon },
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object as PropType<WeightedOption[]>,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dialog = useDialog();
    const inputValue = useVModel(props, 'modelValue');

    const weightOptions = Array.from(Array(20).keys()).map((i) => ({
      value: i + 1,
      label: i + 1
    }));

    const sortedList = computed(() => {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return inputValue.value.sort((a, b) => {
        if (a.weight === b.weight) {
          return a.key < b.key ? -1 : 1;
        }
        return a.weight < b.weight ? -1 : 1;
      });
    });

    const onRemove = (key: number): void => {
      dialog.warning({
        title: 'Confirm',
        content: 'Are you sure want to remove this option? If this is part of a Survey that has already been sent out, you may NOT want to remove options.',
        positiveText: 'Yes, remove it',
        negativeText: 'No, keep it',
        onPositiveClick: () => {
          inputValue.value.splice(
            inputValue.value.findIndex((option) => option.key === key),
            1
          );
        }
      });
    };

    const getNextKey = () => {
      if (inputValue.value.length === 0) {
        return 1;
      }

      // return max value of inputValue.value
      return Math.max(...inputValue.value.map((option) => option.key)) + 1;
    };

    const getNextWeight = () => {
      if (inputValue.value.length === 0) {
        return 1;
      }

      // return max value of inputValue.value
      return Math.max(...inputValue.value.map((option) => option.weight)) + 1;
    };

    const onCreate = () => {
      inputValue.value.push({
        key: getNextKey(),
        weight: getNextWeight(),
        label: 'New Option'
      });
    };

    return {
      sortedList,
      weightOptions,
      onRemove,
      onCreate
    };
  }
});
