
import { defineComponent } from 'vue';
import { PencilOutline as EditIcon, TrashBinOutline as DeleteIcon } from '@vicons/ionicons5';

export default defineComponent({
  name: 'UserActions',
  components: {
    EditIcon,
    DeleteIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['edit', 'delete'],
  methods: {
    editUser() {
      this.$emit('edit', this.user);
    },
    deleteUser() {
      this.$emit('delete', this.user);
    }
  }
});
