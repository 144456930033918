
import { defineComponent, PropType } from 'vue';
import { SelectOption } from 'naive-ui';
import { useVModel } from 'vue-composable';
import { AddSharp as CreateIcon, RemoveOutline as RemoveIcon } from '@vicons/ionicons5';

export default defineComponent({
  name: 'TwoColumnOptionsEditor',
  props: {
    columnOneLabel: {
      type: String,
      required: true
    },
    columnTwoLabel: {
      type: String,
      required: true
    },
    columnOnePath: {
      type: String,
      required: true
    },
    columnTwoPath: {
      type: String,
      required: true
    },
    columnOneModelValue: {
      type: Object as PropType<SelectOption[]>,
      required: true
    },
    columnTwoModelValue: {
      type: Object as PropType<SelectOption[]>,
      required: true
    }
  },
  components: { CreateIcon, RemoveIcon },
  setup(props) {
    const columnOneModelValueRef = useVModel(props, 'columnOneModelValue');
    const columnTwoModelValueRef = useVModel(props, 'columnTwoModelValue');

    const onCreate = () => {
      const newIndex = columnOneModelValueRef.value.length + 1;

      columnOneModelValueRef.value.push({
        label: `Option ${newIndex}`,
        key: newIndex
      });

      columnTwoModelValueRef.value.push({
        label: `#9B4DCA`,
        key: newIndex
      });
    };
    const onRemove = (index: number) => {
      if (index !== -1) {
        columnOneModelValueRef.value = props.columnOneModelValue.filter((_, i) => i !== index);
        columnTwoModelValueRef.value = props.columnTwoModelValue.filter((_, i) => i !== index);
      }
    };

    return {
      columnOneModelValueRef,
      columnTwoModelValueRef,
      onCreate,
      onRemove
    };
  }
});
