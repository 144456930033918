
import { defineComponent, PropType } from 'vue';
import { useVModel } from 'vue-composable';

export default defineComponent({
  name: 'Modal',
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    modalContent: {
      type: Object as PropType<ReturnType<typeof defineComponent>>,
      required: true
    },
    modalContentProps: {
      type: Object,
      required: false,
      default: () => ({})
    },
    modalOnPositiveClick: {
      type: Function as PropType<() => void>,
      required: true
    },
    modalOnNegativeClick: {
      type: Function as PropType<() => void>,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    modalType: {
      type: String,
      required: false
    },
    modalWidth: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const showModalRef = useVModel(props, 'showModal');
    return {
      showModalRef
    };
  }
});
