import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","justify-content":"space-between"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_form_item = _resolveComponent("n-form-item")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_form = _resolveComponent("n-form")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: _ctx.showModalRef,
    "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showModalRef) = $event)),
    preset: "card",
    title: _ctx.formModel.id === 'new' ? 'Invite User' : 'Edit User',
    style: {"width":"40%"},
    onClose: _ctx.resetFormModel,
    "block-scroll": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_form, {
        ref: "formRef",
        model: _ctx.formModel,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_form_item, {
            label: "Name",
            path: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_input, {
                value: _ctx.formModel.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.name) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_n_form_item, {
            label: "Email",
            path: "email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_input, {
                value: _ctx.formModel.email,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.email) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_n_form_item, {
            label: "Role",
            path: "role"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_select, {
                value: _ctx.formModel.role,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.role) = $event)),
                options: _ctx.roles
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          (_ctx.formModel.role !== 'Admin')
            ? (_openBlock(), _createBlock(_component_n_form_item, {
                key: 0,
                label: "Regions",
                path: "regions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_select, {
                    multiple: "",
                    filterable: "",
                    options: _ctx.regionOptions,
                    value: _ctx.formModel.regions,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.regions) = $event))
                  }, null, 8, ["options", "value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_n_button, { onClick: _ctx.closeModal }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_n_button, {
              onClick: _ctx.saveUser,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["show", "title", "onClose"]))
}