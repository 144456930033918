import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h1 = _resolveComponent("n-h1")!
  const _component_create_icon = _resolveComponent("create-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_data_table = _resolveComponent("n-data-table")!
  const _component_UserEditModal = _resolveComponent("UserEditModal")!
  const _component_ConfirmUserDeleteModal = _resolveComponent("ConfirmUserDeleteModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_space, { justify: "space-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_n_h1, null, {
          default: _withCtx(() => [
            _createTextVNode("Users")
          ]),
          _: 1
        }),
        _createVNode(_component_n_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addUser()))
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_n_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_create_icon)
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createTextVNode(" Create User ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.regionsLoaded && _ctx.usersLoaded)
        ? (_openBlock(), _createBlock(_component_n_input, {
            key: 0,
            autofocus: "",
            type: "text",
            placeholder: "Search",
            value: _ctx.searchQuery,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
            clearable: ""
          }, {
            suffix: _withCtx(() => [
              _createVNode(_component_n_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_search_icon)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.usersLoaded)
      ? (_openBlock(), _createBlock(_component_n_data_table, {
          key: 0,
          columns: _ctx.columns,
          data: _ctx.filteredData,
          bordered: false,
          pagination: _ctx.pagination,
          class: "user-list"
        }, null, 8, ["columns", "data", "pagination"]))
      : _createCommentVNode("", true),
    _createVNode(_component_UserEditModal, {
      showModal: _ctx.showUserEditModal,
      "onUpdate:showModal": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showUserEditModal) = $event)),
      onUserUpdated: _ctx.updateUserInList,
      onUserCreated: _ctx.addUserToList,
      regionOptions: _ctx.regionOptions,
      user: _ctx.selectedUser
    }, null, 8, ["showModal", "onUserUpdated", "onUserCreated", "regionOptions", "user"]),
    _createVNode(_component_ConfirmUserDeleteModal, {
      showModal: _ctx.showConfirmDeleteModal,
      "onUpdate:showModal": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showConfirmDeleteModal) = $event)),
      user: _ctx.selectedUser,
      onUserDelete: _ctx.removeUserFromList
    }, null, 8, ["showModal", "user", "onUserDelete"])
  ], 64))
}