import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4850cf0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h1 = _resolveComponent("n-h1")!
  const _component_back_icon = _resolveComponent("back-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_save_icon = _resolveComponent("save-icon")!
  const _component_discard_icon = _resolveComponent("discard-icon")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_data_table = _resolveComponent("n-data-table")!
  const _component_DataSummarySection = _resolveComponent("DataSummarySection")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_space, { justify: "space-between" }, {
      default: _withCtx(() => [
        _createVNode(_component_n_h1, null, {
          default: _withCtx(() => [
            _createTextVNode("Data Cleanup")
          ]),
          _: 1
        }),
        _createVNode(_component_n_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_n_button, {
              class: "back",
              onClick: _ctx.navigateToGraph
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_n_icon, {
                  size: "17",
                  class: "back-icon"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_back_icon)
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Back to Graph ")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_n_button, {
              onClick: _ctx.saveData,
              type: "primary"
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_n_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_save_icon)
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Save Changes   "),
                (_ctx.changeCount > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_1, " (" + _toDisplayString(_ctx.changeCount) + ") ", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_n_button, {
              onClick: _ctx.toggleDiscardMenu,
              type: "error"
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_n_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_discard_icon)
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Discard Changes ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_n_input, {
          key: 0,
          autofocus: "",
          type: "text",
          placeholder: "Search",
          value: _ctx.searchQuery,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          clearable: ""
        }, {
          suffix: _withCtx(() => [
            _createVNode(_component_n_icon, null, {
              default: _withCtx(() => [
                _createVNode(_component_search_icon)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_n_data_table, {
          key: _ctx.tableKey,
          columns: _ctx.columns,
          data: _ctx.filteredData,
          bordered: false,
          "row-key": (row) => row.id,
          class: "user-list"
        }, null, 8, ["columns", "data", "row-key"]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_DataSummarySection, {
          key: 2,
          dataColumns: _ctx.dataColumns,
          showPersonSummary: _ctx.showPersonSummary,
          organizationTypeList: _ctx.survey?.organizationTypeList ?? []
        }, null, 8, ["dataColumns", "showPersonSummary", "organizationTypeList"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal, {
      modalContent: _ctx.ConfirmDiscardChanges,
      modalOnNegativeClick: () => (_ctx.showDiscardModal = false),
      modalOnPositiveClick: 
      () => {
        _ctx.discardChanges();
        _ctx.showDiscardModal = false;
      }
    ,
      showModal: _ctx.showDiscardModal,
      modalTitle: "Discard Changes",
      modalType: "dialog"
    }, null, 8, ["modalContent", "modalOnNegativeClick", "modalOnPositiveClick", "showModal"])
  ], 64))
}