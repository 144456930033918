
import { defineComponent, computed } from 'vue';
import { ResponseDataColumn } from '../../types/response-data-column';
import MatchIndicator from './MatchIndicator.vue';

export default defineComponent({
  name: 'DataSummary',
  components: {
    MatchIndicator
  },
  props: {
    data: {
      type: Object as () => ResponseDataColumn[],
      required: true
    },
    summaryType: {
      type: String as () => 'organization' | 'person',
      required: false,
      default: 'organization'
    },
    organizationTypeList: {
      type: Object as () => Array<{ label: string; key: number }>,
      required: true
    }
  },
  setup(props) {
    const organizationFieldClass = computed(() => {
      return {
        'summary-detail': true,
        primary: props.summaryType === 'organization'
      };
    });

    return {
      organizationFieldClass
    };
  }
});
