import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","width":"100%","min-height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialGraph = _resolveComponent("SocialGraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SocialGraph, {
      survey: _ctx.survey,
      isPublic: _ctx.isPublic
    }, null, 8, ["survey", "isPublic"])
  ]))
}