import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","vertical-align":"middle","font-size":"16px","margin":"0"} }
const _hoisted_2 = { style: {"max-width":"20rem"} }
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_4 = { style: {"text-align":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning_icon = _resolveComponent("warning-icon")!
  const _component_success_icon = _resolveComponent("success-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_tooltip = _resolveComponent("n-tooltip")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_n_tooltip, { key: 0 }, {
        trigger: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_n_icon, {
              size: "25",
              color: _ctx.matches < 1 ? '#f0a020' : '#28837f'
            }, {
              default: _withCtx(() => [
                (_ctx.matches < 1)
                  ? (_openBlock(), _createBlock(_component_warning_icon, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_success_icon, { key: 1 }))
              ]),
              _: 1
            }, 8, ["color"]),
            _createElementVNode("p", _hoisted_1, _toDisplayString(`(${_ctx.matches})`), 1)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.matches) + " " + _toDisplayString('Match' + (_ctx.matches !== 1 ? 'es' : '')), 1),
            (_ctx.matches < 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, "This entry has 0 " + _toDisplayString(_ctx.matchTypeText) + " matches. Are you sure this data is accurate?", 1))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.matchTypeText == 'person' ? _ctx.personMatchExplanation : _ctx.orgMatchExplanation), 1)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}