
import { defineComponent, provide } from 'vue';
import { NConfigProvider } from 'naive-ui';
import Header from '@/components/nav/Header.vue';
import Error from '@/components/Error.vue';
import { useAuth0 } from '@/auth';
import { InjectionKeys } from '@/constants';

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Error,
    NConfigProvider
  },
  setup() {
    const auth = useAuth0();
    provide(InjectionKeys.Auth, auth);

    const primaryColor = auth.state.isAuthenticated ? '#0777A7' : '#28837F';
    return {
      /**
       * @type import('naive-ui').GlobalThemeOverrides
       */
      themeOverrides: {
        common: {
          primaryColor,
          primaryColorHover: primaryColor
        },
        Layout: {
          headerColor: primaryColor,
          footerColor: '#EAEAEA'
        },
        Table: {
          borderColor: primaryColor
        },
        Button: {
          textColor: primaryColor
        },
        LoadingBar: {
          colorLoading: '#fff'
        }
      },
      ...auth
    };
  }
});
