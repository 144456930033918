import { SelectOption } from 'naive-ui';

export const mapToSelectOption = <T extends { [key: string]: unknown }>(list: T[] | undefined, value: keyof T, label: keyof T): SelectOption[] | undefined =>
  list?.map(
    (item) =>
      ({
        value: item[value],
        label: item[label]
      } as SelectOption)
  );
