import api from './api';
import { User } from '@/types';

const rootPath = '/user';

export default {
  getMe: (): Promise<User> => {
    return api.get(`${rootPath}`).then(({ data }) => data);
  },
  get: (id: string): Promise<User> => {
    return api.get(`${rootPath}/${id}`).then(({ data }) => data);
  },
  getAll: (): Promise<User[]> => {
    return api.get(`${rootPath}/all`).then(({ data }) => data);
  },
  getManagers: (): Promise<User[]> => {
    return api.get(`${rootPath}/managers`).then(({ data }) => data);
  },
  update: (user: User): Promise<User> => {
    return api.put(`${rootPath}/${user.id}`, user).then(({ data }) => data);
  },
  create: (user: User): Promise<User> => {
    return api.post(rootPath, user).then(({ data }) => data);
  },
  delete: (id: string): Promise<void> => {
    return api.delete(`${rootPath}/${id}`);
  }
};
