
import { inject, ref } from 'vue';
import axios from 'axios';
import { InjectionKeys } from '@/constants';
import { Auth0Manager } from '@/auth';

export default {
  name: 'Api',
  setup() {
    const auth = inject(InjectionKeys.Auth) as Auth0Manager;

    const apiUrl = `${process.env.VUE_APP_API_URL}/surveys`;
    let apiMessage = ref('');
    const executed = ref(false);

    const callApi = async () => {
      const accessToken = await auth.getTokenSilently();
      // console.log(apiUrl);
      // console.log(accessToken);
      const { data } = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      // console.log(data);
      apiMessage = data;
      // executed = true;
    };

    return {
      apiMessage,
      apiUrl,
      executed,
      callApi
    };
  }
};
