import api from './api';
import publicApi from './public-api';
import { Survey } from '@/types';

const rootPath = '/surveys';

export default {
  getList: async (): Promise<Survey[]> => {
    return api.get(rootPath).then(({ data }) => data);
  },

  get: (id: string): Promise<Survey> => {
    return publicApi.get(`${rootPath}/${id}`).then(({ data }) => data);
  },

  getDefault: (): Promise<Survey> => {
    return api.get(`${rootPath}/default`).then(({ data }) => data);
  },

  create: (survey: Survey): Promise<Survey> => {
    return api.post(rootPath, survey).then(({ data }) => data);
  },

  update: (survey: Survey): Promise<Survey> => {
    return api.put(`${rootPath}/${survey.id}`, survey).then(({ data }) => data);
  },
  delete: (id: string): Promise<void> => {
    return api.delete(`${rootPath}/${id}`);
  },
  getSurveyToTake: (takeGuid: string): Promise<Survey> => {
    return publicApi.get(`${rootPath}/take/${takeGuid}`).then(({ data }) => data);
  },
  getShareable: (shareResultsGuid: string): Promise<Survey> => {
    return publicApi.get(`${rootPath}/shared/${shareResultsGuid}`).then(({ data }) => data);
  }
};
