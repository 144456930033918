
import { defineComponent } from 'vue';
import { useVModel } from 'vue-composable';
import { useMessage } from 'naive-ui';
import { WarningOutline as WarningIcon } from '@vicons/ionicons5';
import { User } from '@/types';
import userService from '@/services/user-service';

export default defineComponent({
  name: 'ConfirmUserDeleteModal',
  props: {
    user: {
      type: Object as () => User | null,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  },
  components: { WarningIcon },
  setup(props, { emit }) {
    const showModalRef = useVModel(props, 'showModal');
    const message = useMessage();
    const confirmDelete = () => {
      userService
        .delete(props.user?.id ?? '')
        .then(() => {
          emit('user-delete', props.user?.id);
          message.success('User deleted');
        })
        .catch(() => {
          message.error('Failed to delete user');
        })
        .finally(() => {
          showModalRef.value = false;
        });
    };

    const cancelDelete = () => {
      showModalRef.value = false;
    };

    return {
      confirmDelete,
      cancelDelete,
      showModalRef
    };
  }
});
