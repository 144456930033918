import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-right":"0.5rem","margin-left":"0.5rem"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning_icon = _resolveComponent("warning-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_h2 = _resolveComponent("n-h2")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, {
    show: _ctx.showModalRef,
    "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModalRef) = $event)),
    preset: "card",
    class: "confirm-delete-modal",
    "block-scroll": false
  }, {
    header: _withCtx(() => [
      _createVNode(_component_n_space, {
        align: "center",
        inline: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_icon, {
            size: "30",
            color: "#f0a020"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_warning_icon)
            ]),
            _: 1
          }),
          _createVNode(_component_n_h2, { style: {"margin-bottom":"0.5rem"} }, {
            default: _withCtx(() => [
              _createTextVNode("Confirm Delete")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_n_space, null, {
        default: _withCtx(() => [
          _createVNode(_component_n_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelDelete()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_component_n_button, {
            type: "error",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmDelete()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Delete")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, " Are you sure you want to delete this survey? " + _toDisplayString((_ctx.survey?.sentCount ?? 0) > 0 ? `It has been sent to ${_ctx.survey?.sentCount} people.` : ''), 1)
    ]),
    _: 1
  }, 8, ["show"]))
}