
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useMessage } from 'naive-ui';
import surveyService from '@/services/survey-service';
import LoadingBar from '@/objects/loading-bar';
import { Survey } from '@/types/survey';
import SocialGraph from '@/components/graph/SocialGraph.vue';

export default defineComponent({
  name: 'SurveyResults',
  props: {
    surveyId: {
      type: String,
      required: true
    },
    isPublic: {
      type: Boolean
    }
  },
  components: {
    SocialGraph
  },
  setup(props) {
    const survey = ref<Survey | null>(null);
    const loadingBar = new LoadingBar();
    const message = useMessage();

    const fetchSurvey = () => {
      loadingBar.start();
      if (props.isPublic) {
        surveyService.getShareable(props.surveyId).then((data) => {
          survey.value = data;
          loadingBar.finish();
        });
      } else {
        surveyService
          .get(props.surveyId)
          .then((data) => {
            survey.value = data;
            loadingBar.finish();
          })
          .catch((error) => {
            message.error(error.message);
            loadingBar.error();
          });
      }
    };

    onMounted(() => {
      fetchSurvey();
      document.getElementsByClassName('n-layout-content')[0].classList.add('social-graph-page');
    });
    onUnmounted(() => {
      document.getElementsByClassName('n-layout-content')[0].classList.remove('social-graph-page');
    });

    return {
      survey
    };
  }
});
