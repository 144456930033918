
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { useVModel } from 'vue-composable';
import { ResponseDataColumn } from '../../types/response-data-column';
import DataSummary from './DataSummary.vue';

export default defineComponent({
  name: 'DataSummarySection',
  components: {
    DataSummary
  },
  props: {
    dataColumns: {
      type: Array as () => ResponseDataColumn[],
      required: true
    },
    showPersonSummary: {
      type: Boolean,
      required: false,
      default: false
    },
    organizationTypeList: {
      type: Array as () => Array<{ label: string; key: number }>,
      required: true
    }
  },
  setup(props) {
    const state = reactive({
      loaded: false,
      data: [] as ResponseDataColumn[]
    });

    const showPersonSummaryRef = useVModel(props, 'showPersonSummary');
    const organizationTypeListRef = useVModel(props, 'organizationTypeList');
    const personAggregatedData = computed(() => {
      const personRowList = [] as ResponseDataColumn[];

      for (let i = 0; i < state.data.length; i += 1) {
        if (!personRowList.find((item) => item.personIdentifier === state.data[i].personIdentifier)) {
          personRowList.push(state.data[i]);
        }
      }

      personRowList.sort((a, b) => {
        const nameComparison = a.cleanName?.localeCompare(b.cleanName ?? '') as number;
        if (nameComparison !== 0) {
          return nameComparison;
        }
        return a.cleanOrganization?.localeCompare(b.organization) as number;
      });
      return personRowList;
    });

    const organizationAggregatedData = computed(() => {
      const organizationRowList = [] as ResponseDataColumn[];

      for (let i = 0; i < state.data.length; i += 1) {
        if (!organizationRowList.find((item) => item.organizationIdentifier === state.data[i].organizationIdentifier)) {
          organizationRowList.push(state.data[i]);
        }
      }

      organizationRowList.sort((a, b) => {
        const organizationComparison = a.cleanOrganization?.localeCompare(b.cleanOrganization ?? '') as number;
        if (organizationComparison !== 0) {
          return organizationComparison;
        }

        const aOrganizationTypeLabel = organizationTypeListRef.value.find((type) => type.key === a.cleanOrganizationType)?.label || '';
        const bOrganizationTypeLabel = organizationTypeListRef.value.find((type) => type.key === b.cleanOrganizationType)?.label || '';

        return aOrganizationTypeLabel.localeCompare(bOrganizationTypeLabel);
      });
      return organizationRowList;
    });

    onMounted(() => {
      state.data = props.dataColumns;
      state.loaded = true;
    });
    return {
      ...toRefs(state),
      personAggregatedData,
      organizationAggregatedData,
      showPersonSummaryRef,
      organizationTypeListRef
    };
  }
});
