
import { computed, defineComponent, reactive, toRefs } from 'vue';
import { PageState, Survey } from '@/types';
import LoadingBar from '@/objects/loading-bar';

export default defineComponent({
  name: 'SurveyMainPage',
  props: {
    name: {
      type: String
    },
    introduction: {
      type: String
    },
    participation: {
      type: String
    },
    benefits: {
      type: String
    },
    confidentiality: {
      type: String
    },
    questions: {
      type: String
    },
    hasAgreed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:hasAgreed'],
  setup(props, { emit }) {
    const loadingBar = new LoadingBar();
    loadingBar.start();

    const hasAgreedRef = computed({
      get: () => props.hasAgreed,
      set: (value) => emit('update:hasAgreed', value)
    });

    const state = reactive<PageState<Survey | undefined>>({
      loaded: false,
      data: undefined
    });

    const setAgreed = (value: boolean) => {
      hasAgreedRef.value = value;
    };

    return {
      ...toRefs(state),
      hasAgreedRef,
      setAgreed
    };
  }
});
