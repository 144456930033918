
import { defineComponent, reactive, onMounted, toRefs, computed, h, ref } from 'vue';
import { useRouter } from 'vue-router';
import { SearchOutline as SearchIcon, SaveOutline as SaveIcon, TrashOutline as DiscardIcon, ArrowBack as BackIcon } from '@vicons/ionicons5';
import { useMessage } from 'naive-ui';
import { NetworkType, Survey } from '../../types/survey';
import { Response } from '../../types/response';
import { PageState } from '../../types/page-state';
import LoadingBar from '../../objects/loading-bar';
import responseService from '@/services/response-service';
import { ResponseDataColumn } from '../../types/response-data-column';
import surveyService from '@/services/survey-service';
import { useFormatters } from '../../composables/useFormatters';
import { createIdentifierFromStrings } from '../../utils/identifierUtils';
import { mapDataToResponseColumns } from '../../utils/dataCleanupUtils';
import MatchIndicator from './MatchIndicator.vue';
import ConfirmDiscardChanges from './ConfirmDiscardChanges.vue';
import Modal from '../shared/Modal.vue';
import InputWithReset from '../shared/text-input-with-reset/InputWithReset.vue';
import DataSummarySection from './DataSummarySection.vue';
import { ResettableInputType } from '../../types/resettable-input-type';

export default defineComponent({
  name: 'DataCleanupComponent',
  components: { SearchIcon, SaveIcon, DiscardIcon, Modal, DataSummarySection, BackIcon },
  props: {
    surveyId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const state = reactive<
      PageState<Response[]> & {
        surveyId: string;
        survey: Survey | null;
        searchQuery: string;
        dataColumns: ResponseDataColumn[];
        changeCount: number;
        showDiscardModal: boolean;
      }
    >({
      loaded: false,
      data: [] as Response[],
      surveyId: '',
      survey: null,
      searchQuery: '',
      dataColumns: [],
      changeCount: 0,
      showDiscardModal: false
    });

    const { formatDate } = useFormatters();

    const loadingBar = new LoadingBar();
    const message = useMessage();
    const router = useRouter();

    const orgTypeList = computed(() => state.survey?.organizationTypeList.map((orgType) => ({ label: orgType.label, value: orgType.key })) || []);
    const personIdentifierList = computed(() => state.dataColumns.map((row) => row.personIdentifier));
    const organizationIdentifierList = computed(() => state.dataColumns.map((row) => row.organizationIdentifier));

    const tableKey = ref(true);

    const showPersonSummary = computed(() => state.survey?.questions.network.networkType === NetworkType.ContactAndOrganization);

    const checkMatches = (row: ResponseDataColumn) => {
      const personMatches = personIdentifierList.value.filter((id) => id === row.personIdentifier).length;
      const orgMatches = organizationIdentifierList.value.filter((id) => id === row.organizationIdentifier).length;

      row.personMatches = personMatches - 1;
      row.organizationMatches = orgMatches - 1;

      row.personMatchList = state.dataColumns.filter((r) => r.personIdentifier === row.personIdentifier).map((r) => r.cleanName as string);
    };

    const updateMatches = () => {
      state.dataColumns.forEach((row) => {
        checkMatches(row);
      });
    };

    const processRowChange = (row: ResponseDataColumn, field: keyof ResponseDataColumn, value: any) => {
      if (row[field] !== value) {
        row[field] = value as never;
        if (!row.hasChanged) state.changeCount += 1;
        row.hasChanged = true;
      }

      if (!row.hasChanged) return;

      row.personIdentifier = createIdentifierFromStrings([row.cleanName as string, row.cleanOrganization as string, row.cleanOrganizationType?.toString() as string]);

      row.organizationIdentifier = createIdentifierFromStrings([row.cleanOrganization as string, row.cleanOrganizationType?.toString() as string]);

      updateMatches();

      state.dataColumns = [...state.dataColumns];
    };

    const columnsWithName = [
      {
        title: 'Organization',
        key: 'cleanOrganization',
        render: (row: ResponseDataColumn) =>
          h(InputWithReset, {
            modelValue: row.cleanOrganization as string,
            fieldKey: 'Organization',
            onUpdateValue: (value) => processRowChange(row, 'cleanOrganization', value),
            resetValue: row.organization,
            tableRow: row,
            promptBeforeReset: true
          })
      },
      {
        title: 'Organization Type',
        key: 'cleanOrganizationType',
        render: (row: ResponseDataColumn) =>
          h(InputWithReset, {
            modelValue: row.cleanOrganizationType ?? 1,
            fieldKey: 'Organization Type',
            onUpdateValue: (value) => processRowChange(row, 'cleanOrganizationType', value),
            resetValue: row.organizationType ?? 1,
            tableRow: row,
            promptBeforeReset: true,
            inputType: ResettableInputType.SELECT,
            selectOptions: orgTypeList.value
          })
      },
      {
        title: '',
        render: (row: ResponseDataColumn) => h(MatchIndicator, { matchList: row.personMatchList, matchNumber: row.organizationMatches, matchType: 'organization' })
      },
      {
        title: 'Name',
        key: 'cleanName',
        render: (row: ResponseDataColumn) =>
          h(InputWithReset, {
            modelValue: row.cleanName as string,
            fieldKey: 'Name',
            onUpdateValue: (value) => processRowChange(row, 'cleanName', value),
            resetValue: row.name,
            tableRow: row,
            promptBeforeReset: true
          })
      },
      {
        title: '',
        render: (row: ResponseDataColumn) => h(MatchIndicator, { matchList: row.personMatchList, matchNumber: row.personMatches, matchType: 'person' })
      },
      {
        title: 'Submitted On',
        key: 'submittedOn',
        render: (row: ResponseDataColumn) => formatDate(row.submittedOn as string)
      }
    ];

    const columnsWithoutName = [
      {
        title: 'Organization',
        key: 'cleanOrganization',
        render: (row: ResponseDataColumn) =>
          h(InputWithReset, {
            modelValue: row.cleanOrganization as string,
            fieldKey: 'Organization',
            onUpdateValue: (value) => processRowChange(row, 'cleanOrganization', value),
            resetValue: row.organization,
            tableRow: row,
            promptBeforeReset: true
          })
      },
      {
        title: 'Organization Type',
        key: 'cleanOrganizationType',
        render: (row: ResponseDataColumn) =>
          h(InputWithReset, {
            modelValue: row.cleanOrganizationType ?? 1,
            fieldKey: 'Organization Type',
            onUpdateValue: (value) => processRowChange(row, 'cleanOrganizationType', value),
            resetValue: row.organizationType ?? 1,
            tableRow: row,
            promptBeforeReset: true,
            inputType: ResettableInputType.SELECT,
            selectOptions: orgTypeList.value
          })
      },
      {
        title: '',
        render: (row: ResponseDataColumn) => h(MatchIndicator, { matchNumber: row.organizationMatches, matchType: 'organization' })
      },
      {
        title: 'Submitted On',
        key: 'submittedOn',
        render: (row: ResponseDataColumn) => formatDate(row.submittedOn as string)
      }
    ];

    const columns = computed(() => (state.survey?.questions.network.networkType === NetworkType.ContactAndOrganization ? columnsWithName : columnsWithoutName));

    const filteredData = computed(() => {
      const query = state.searchQuery.toLowerCase();
      return state.dataColumns.filter((row) => {
        return (
          row.organization.toLowerCase().includes(query) ||
          (row.cleanOrganization?.toLowerCase() ?? '').includes(query) ||
          row.name.toLowerCase().includes(query) ||
          (row.cleanName?.toLowerCase() ?? '').includes(query)
        );
      });
    });

    const updateResponse = async (row: ResponseDataColumn) => {
      const response = state.data.find((res) => res.id === row.responseId);
      if (response) {
        if (row.isInYourNetwork) {
          const networkEntry = response.network.yourNetwork.find(
            (net) => net.name === row.name && net.organization === row.organization && net.organizationType === row.organizationType
          );
          if (networkEntry) {
            networkEntry.cleanName = row.cleanName;
            networkEntry.cleanOrganization = row.cleanOrganization;
            networkEntry.cleanOrganizationType = row.cleanOrganizationType;
          }
        } else {
          response.info.cleanName = row.cleanName;
          response.info.cleanOrganization = row.cleanOrganization;
          response.info.cleanOrganizationType = row.cleanOrganizationType;
        }
      } else {
        message.error('Failed to update response');
      }
    };

    const saveData = async () => {
      loadingBar.start();
      const changedRows = state.dataColumns.filter((row) => row.hasChanged);
      try {
        changedRows.forEach(async (row) => {
          await updateResponse(row);
        });

        const changedResponses = state.data.filter((res) => changedRows.find((row) => row.responseId === res.id));
        const updateResponseResult = await responseService.updateMany(changedResponses);

        if (!updateResponseResult) throw Error('Failed to update responses');

        message.success('Changes saved');
        loadingBar.finish();
        state.changeCount = 0;
      } catch (error) {
        console.error('Failed to update response', error);
        loadingBar.error();
        message.error('Failed to save changes');
      }
    };

    const discardChanges = () => {
      tableKey.value = !tableKey.value;
      // reset the rows to the original response data, so call map again and set the dataColumns
      state.loaded = false;
      state.dataColumns = mapDataToResponseColumns(state.data);
      // state.filteredData = state.dataColumns;
      state.changeCount = 0;

      updateMatches();
      state.loaded = true;
    };

    const toggleDiscardMenu = () => {
      state.showDiscardModal = !state.showDiscardModal;
    };

    const navigateToGraph = () => {
      // eslint-disable-next-line
      router.push(`/admin/survey/${state.surveyId}/results`);
    };

    onMounted(async () => {
      loadingBar.start();
      state.surveyId = props.surveyId;
      try {
        // fetch survey
        const survey = await surveyService.get(state.surveyId);
        state.survey = survey;

        // fetch responses
        const responses = await responseService.getList(state.surveyId);
        state.data = responses;
        state.dataColumns = mapDataToResponseColumns(state.data);

        updateMatches();

        state.loaded = true;
        loadingBar.finish();
      } catch (error) {
        loadingBar.error();
        message.error('Failed to load data');
      }
      loadingBar.finish();
    });
    return {
      ...toRefs(state),
      columns,
      filteredData,
      updateResponse,
      saveData,
      discardChanges,
      formatDate,
      ConfirmDiscardChanges,
      toggleDiscardMenu,
      processRowChange,
      tableKey,
      showPersonSummary,
      navigateToGraph
    };
  }
});
