
import { defineComponent, reactive, onMounted, toRefs, watch } from 'vue';
import { WarningOutline as WarningIcon, CheckmarkOutline as SuccessIcon } from '@vicons/ionicons5';

export default defineComponent({
  name: 'MatchIndicator',
  components: { WarningIcon, SuccessIcon },
  props: {
    matchNumber: {
      type: Number,
      required: true
    },
    matchType: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const state = reactive({
      loaded: false,
      matches: 0,
      matchTypeText: ''
    });

    const personMatchExplanation = 'Matches are based on the following fields: Name, Organization, and Organization Type.';
    const orgMatchExplanation = 'Matches are based on the following fields: Organization and Organization Type.';

    watch(
      () => props.matchNumber,
      (newValue) => {
        state.matches = newValue;
      }
    );

    onMounted(() => {
      state.matches = props.matchNumber;
      state.matchTypeText = props.matchType;

      state.loaded = true;
    });

    return {
      ...toRefs(state),
      personMatchExplanation,
      orgMatchExplanation
    };
  }
});
