import { useLoadingBar } from 'naive-ui';

export default class LoadingBar {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadingBar: any;

  delayInMs = 100;

  constructor(delayInMs?: number) {
    if (delayInMs) {
      this.delayInMs = delayInMs;
    }
    this.loadingBar = useLoadingBar();
  }

  start(): void {
    this.loadingBar.start();
  }

  finish(): void {
    setTimeout(() => this.loadingBar.finish(), this.delayInMs);
  }

  error(): void {
    setTimeout(() => this.loadingBar.error(), this.delayInMs);
  }
}
