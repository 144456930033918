
import { defineComponent, PropType, reactive, ref, toRefs, watch } from 'vue';
import { useVModel } from 'vue-composable';
import { computed } from '@vue/reactivity';
import { SyncOutline as ResetIcon } from '@vicons/ionicons5';
import ConfirmTextFieldResetModal from './ConfirmTextFieldResetModal.vue';
import { ResponseDataColumn } from '../../../types/response-data-column';
import { ResettableInputType } from '../../../types/resettable-input-type';

export default defineComponent({
  name: 'InputWithReset',
  components: {
    ConfirmTextFieldResetModal,
    ResetIcon
  },
  props: {
    modelValue: {
      type: Object as PropType<string | number>,
      required: true
    },
    resetValue: {
      type: Object as PropType<string | number>,
      required: false
    },
    promptBeforeReset: {
      type: Boolean,
      required: false,
      default: false
    },
    fieldKey: {
      type: String,
      required: true
    },
    onUpdateValue: {
      type: Function as PropType<(value: any) => void>,
      required: false
    },
    tableRow: {
      type: Object as PropType<ResponseDataColumn>,
      required: true
    },
    inputType: {
      type: Object as PropType<ResettableInputType>,
      required: false,
      default: ResettableInputType.TEXT
    },
    selectOptions: {
      type: Array as PropType<{ label: string; value: number }[]>,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const state = reactive({
      showConfirmResetModal: false,
      promptBeforeReset: props.promptBeforeReset,
      modalContentProps: {},
      fieldName: props.fieldKey,
      onUpdateValueFunction: props.onUpdateValue,
      tableRow: props.tableRow,
      inputTypeRef: ref(props.inputType)
    });

    const valueRef = ref(props.modelValue);
    const resetValueRef = useVModel(props, 'resetValue');
    const selectedOptionsRef = useVModel(props, 'selectOptions');
    const showTextInput = state.inputTypeRef === ResettableInputType.TEXT;
    const showSelectInput = state.inputTypeRef === ResettableInputType.SELECT;
    const buttonStyle = computed(() => (resetValueRef.value === valueRef.value ? 'gray' : ''));
    const fieldValueLabel = computed(
      () =>
        (state.inputTypeRef === ResettableInputType.SELECT && typeof valueRef.value === 'number'
          ? selectedOptionsRef.value.find((option) => option?.value === valueRef.value)?.label ?? valueRef
          : valueRef.value) as string
    );

    const resetValueLabel = computed(
      () =>
        (state.inputTypeRef === ResettableInputType.SELECT && typeof resetValueRef.value === 'number'
          ? selectedOptionsRef.value.find((option) => option?.value === resetValueRef.value)?.label ?? resetValueRef
          : resetValueRef.value) as string
    );

    watch(
      () => props.modelValue,
      (newVal) => {
        valueRef.value = newVal;
      }
    );

    watch(
      () => props.selectOptions,
      (newVal) => {
        selectedOptionsRef.value = newVal;
      }
    );

    const resetField = () => {
      if (state.onUpdateValueFunction && resetValueRef.value !== valueRef.value) {
        state.onUpdateValueFunction(resetValueRef.value);
      }
    };

    const handleReset = () => {
      state.modalContentProps = {
        fieldName: state.fieldName
      };

      if (state.promptBeforeReset) {
        state.showConfirmResetModal = true;
        return;
      }
      resetField();
    };
    return {
      ...toRefs(state),
      resetField,
      valueRef,
      resetValueRef,
      handleReset,
      showTextInput,
      showSelectInput,
      selectedOptionsRef,
      resetValueLabel,
      fieldValueLabel,
      buttonStyle
    };
  }
});
