
import { computed, defineComponent, ref, watch } from 'vue';
import { ArrowBack as BackIcon, CopyOutline as CopyIcon } from '@vicons/ionicons5';
import { useRouter } from 'vue-router';
import { useMessage } from 'naive-ui';
import { Survey, NetworkType } from '@/types';
import { useFormatters } from '@/composables/useFormatters';
import { downloadCSVData } from '../../utils/exportResponseToCSV';
import ConfirmShareableChangeModal from './ConfirmShareableChangeModal.vue';
import ConfirmHideContactsChangeModal from './ConfirmHideContactsChangeModal.vue';

export default defineComponent({
  name: 'SocialGraphSideBar',
  props: {
    survey: {
      type: Object as () => Survey | null,
      required: true
    },
    isPublic: {
      type: Boolean,
      required: true
    }
  },

  components: {
    BackIcon,
    CopyIcon,
    ConfirmShareableChangeModal,
    ConfirmHideContactsChangeModal
  },
  setup(props, { emit }) {
    const surveyRef = computed(() => props.survey);

    const viewOptions = ref<{ label: string; value: string }[]>([
      { label: 'Organization', value: 'organization' },
      { label: 'Person', value: 'person' }
    ]);

    const shareOptions = ref<{ label: string; value: boolean }[]>([
      { label: 'Show Contacts', value: false },
      { label: 'Hide Contacts', value: true }
    ]);

    const showShareableChangeModal = ref(false);
    const showHideContactsChangeModal = ref(false);
    const isShareable = computed(() => surveyRef.value?.shareable);
    const isShareableConfirmed = computed(() => surveyRef.value?.shareable);
    const isPublicValue = computed(() => props.isPublic);
    const router = useRouter();
    const selectedViewOption = ref<string>('organization');
    const hideContacts = computed(() => surveyRef.value?.hideContactsOnShare);

    const { formatDate } = useFormatters();
    const showViewOptions = computed(
      () =>
        (surveyRef.value?.questions.network.networkType === NetworkType.ContactAndOrganization && !isPublicValue.value) ||
        (isPublicValue.value && !surveyRef.value?.hideContactsOnShare && surveyRef.value?.questions.network.networkType === NetworkType.ContactAndOrganization)
    );

    const shareLink = computed(() => {
      return `${window.location.origin}/survey/${surveyRef.value?.shareResultsGUID}/results`;
    });

    const message = useMessage();

    const copyShareLinkToClipboard = () => {
      navigator.clipboard.writeText(shareLink.value);
      message.success('Copied!');
    };

    const toggleShareableChangeModal = () => {
      showShareableChangeModal.value = !showShareableChangeModal.value;
    };

    const toggleHideContactsChangeModal = () => {
      showHideContactsChangeModal.value = !showHideContactsChangeModal.value;
    };

    watch(
      () => selectedViewOption.value,
      (newVal) => {
        emit('view-option-change', newVal);
      }
    );

    return {
      surveyRef,
      formatDate,
      viewOptions,
      selectedViewOption,
      downloadCSVData,
      navigateToList: () => {
        router.push('/admin');
      },
      navigateToDataCleanup: () => {
        router.push(`/admin/survey/${surveyRef.value?.id}/cleanup`);
      },
      showViewOptions,
      shareOptions,
      hideContacts,
      shareLink,
      copyShareLinkToClipboard,
      toggleShareableChangeModal,
      showShareableChangeModal,
      showHideContactsChangeModal,
      isShareable,
      isShareableConfirmed,
      isPublicValue,
      toggleHideContactsChangeModal
    };
  }
});
