
import { defineComponent, reactive, toRefs } from 'vue';
import { useVModel } from 'vue-composable';

export default defineComponent({
  name: 'ConfirmTextFieldResetModal',
  props: {
    fieldValue: {
      type: String,
      required: false,
      default: ''
    },
    resetValue: {
      type: String,
      required: false,
      default: ''
    },
    fieldName: {
      type: String,
      required: false,
      default: ''
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      reset: props.resetValue,
      field: props.fieldName
    });

    const showModalRef = useVModel(props, 'showModal');
    const valueRef = useVModel(props, 'fieldValue');
    const confirmReset = () => {
      emit('confirm-reset');
      showModalRef.value = false;
    };
    return {
      ...toRefs(state),
      showModalRef,
      confirmReset,
      valueRef
    };
  }
});
