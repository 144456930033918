
import { defineComponent } from 'vue';
import RegionAdminList from '@/components/admin/RegionAdminList.vue';

export default defineComponent({
  name: 'Admin',
  components: { RegionAdminList },
  setup() {
    return {};
  }
});
