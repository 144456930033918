import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_icon = _resolveComponent("error-icon")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_h1 = _resolveComponent("n-h1")!
  const _component_n_p = _resolveComponent("n-p")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createBlock(_component_n_space, {
    vertical: "",
    align: "center",
    style: {"padding-block":"7rem"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_icon, {
        size: "80",
        color: "#d03050"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_error_icon)
        ]),
        _: 1
      }),
      _createVNode(_component_n_h1, null, {
        default: _withCtx(() => [
          _createTextVNode("This Survey Does Not Exist")
        ]),
        _: 1
      }),
      _createVNode(_component_n_p, { style: {"text-align":"center"} }, {
        default: _withCtx(() => [
          _createTextVNode("Sorry, the survey you are looking for cannot be found.")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}