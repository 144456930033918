
import { computed, defineComponent, PropType } from 'vue';
import { useBreakpoint, useVModel } from 'vue-composable';
import { useMessage } from 'naive-ui';
import { BREAKPOINTS } from '@/constants';
import { WeightedOption, YourNetwork } from '@/types';

export default defineComponent({
  name: 'SurveyRelationships',
  emits: ['update:relationships'],
  props: {
    text: {
      type: String,
      required: true,
      default: ''
    },
    options: {
      type: Object as PropType<WeightedOption[]>,
      required: true
    },
    yourNetwork: {
      type: Object as PropType<YourNetwork[]>,
      required: true
    },
    userId: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup(props) {
    const { laptop } = useBreakpoint(BREAKPOINTS);
    const yourNetworkRef = useVModel(props, 'yourNetwork');
    const message = useMessage();

    const empty = computed(() => yourNetworkRef.value.some((ref) => ref.relationshipKeys.length === 0));

    const validate = () => {
      const valid = true;
      if (empty.value) {
        message.error('Please select at least one option for each person.');
        return !valid;
      }
      return valid;
    };

    return { laptop, yourNetworkRef, validate };
  }
});
