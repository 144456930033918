
import { computed, inject } from 'vue';
import { Auth0Manager } from '@/auth';
import { InjectionKeys } from '@/constants';

export default {
  name: 'Error',
  setup() {
    const auth = inject(InjectionKeys.Auth) as Auth0Manager;
    const msg = computed(() => auth.state.error);
    return {
      msg
    };
  }
};
