import api from './api';
import publicApi from './public-api';
import { Response } from '@/types';

const rootPath = '/responses';

export default {
  create: (id: string, response: Partial<Response>): Promise<Response> => {
    return api.post(`${rootPath}/${id}`, response).then(({ data }) => data);
  },

  get: (id: string): Promise<Response> => {
    return publicApi.get(`${rootPath}/${id}`).then(({ data }) => data);
  },

  getList: async (surveyId: string): Promise<Response[]> => {
    return api.get(`${rootPath}/export/${surveyId}`).then(({ data }) => data);
  },

  getPublicList: async (shareGuid: string): Promise<Response[]> => {
    return publicApi.get(`${rootPath}/export/shared/${shareGuid}`).then(({ data }) => data);
  },

  update: async (response: Response): Promise<Response> => {
    return publicApi.put(`${rootPath}/${response.id}`, response).then(({ data }) => data);
  },

  updateMany: async (responses: Response[]): Promise<Response[]> => {
    return api.put(`${rootPath}`, responses).then(({ data }) => data);
  }
};
