
import { defineComponent, computed } from 'vue';
import { useMessage } from 'naive-ui';
import { useVModel } from 'vue-composable';
import surveyService from '@/services/survey-service';
import { Survey } from '@/types';

export default defineComponent({
  name: 'ConfirmHideContactsChangeModal',
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    survey: {
      type: Object as () => Survey | null,
      required: true
    }
  },
  setup(props, { emit }) {
    const surveyRef = computed(() => props.survey);
    const showModalRef = useVModel(props, 'showModal');
    const message = useMessage();

    const closeModal = () => {
      emit('resetHideContacts');
      showModalRef.value = false;
    };

    const confirmHideContactsChange = async () => {
      if (!surveyRef.value) return;
      const response = await surveyService.update({ ...surveyRef.value, hideContactsOnShare: !surveyRef.value.hideContactsOnShare });

      let messageText = '';
      if (response) {
        messageText = surveyRef.value.hideContactsOnShare ? 'Contacts are now visible' : 'Contacts are now hidden';
        message.success(messageText);

        emit('updateSurvey', surveyRef.value);
        surveyRef.value.hideContactsOnShare = !surveyRef.value.hideContactsOnShare;
        closeModal();
      } else {
        messageText = surveyRef.value.hideContactsOnShare ? 'Failed to hide contacts' : 'Failed to show contacts';
        message.error(messageText);
        closeModal();
      }
    };
    return {
      surveyRef,
      closeModal,
      confirmHideContactsChange,
      showModalRef
    };
  }
});
