
import { defineComponent } from 'vue';
import SurveyComponent from '@/components/survey/SurveyComponent.vue';

export default defineComponent({
  name: 'Survey',
  components: { SurveyComponent },
  props: {
    takeGuid: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  }
});
