import { WeightedOption, SurveyOptionType, RegionDTO } from '.';

export type SurveyListState = {
  loaded: boolean;
  loading: boolean;
  surveys: Survey[];
};

export type Survey = {
  id: string;
  takeGUID: string;
  shareResultsGUID: string;
  name: string;
  shareable: boolean;
  hideContactsOnShare: boolean;
  description: string;
  emailSubject: string;
  emailBody: string;
  createdOn: string;
  createdBy: string;
  sentCount: number;
  viewCount: number;
  region: RegionDTO;
  completedCount: number;
  participationNetworkQuestion: string;
  details: {
    introduction: string;
    participation: string;
    benefits: string;
    confidentiality: string;
    questions: string;
  };
  questions: {
    network: {
      text: string;
      networkType: NetworkType;
      maximumEntries: number;
    };
    relationships: {
      text: string;
      options: WeightedOption[];
    };
    interactions: {
      text: string;
      options: WeightedOption[];
    };
  };
  organizationTypeList: SurveyOptionType[];
  nodeColors: SurveyOptionType[];
  participationNetworkTypeList: SurveyOptionType[];
};

export enum NetworkType {
  OrganizationOnly = 1,
  ContactAndOrganization = 2
}
